// import { Button } from 'antd'
// import { getMonth } from 'date-fns'
// import { Link } from 'react-router-dom'
// import { handleCreateYear, useUniqueYears } from '../../fetchHooks/useFetchHooks'
import { formatDateFull } from '../../utils/utilFunctions'
import { useEffect, useState } from 'react'

const LastLogin = ({ lastSignin }) => {
  if (!lastSignin) return <></>
  const { loginTime, ipAddress, os, loginStatus, errorMsg, deviceType } = lastSignin

  if (loginStatus === 'success')
    return (
      <p>
        Last login at {formatDateFull(new Date(loginTime))} was successful and came from IP {ipAddress} ({deviceType}/{os})
      </p>
    )
  return (
    <p>
      Last login at {formatDateFull(new Date(loginTime))} failed and came from IP {ipAddress} ({deviceType}/{os}). Error: {errorMsg}
    </p>
  )
}

const Dashboard = ({ user }) => {
  // const { data: years, refetch: refetchYears, isSuccess, isInitialLoading } = useUniqueYears()
  // const currentMonth = getMonth(new Date()) + 1
  // const currentYear = new Date().getFullYear()

  // if (isInitialLoading) <p>Loading years...</p>
  const { lastSignin } = user
  return (
    <div>
      {/* <AppContainer> */}
      <h2>Welcome, {user?.firstname}!</h2>
      <LastLogin lastSignin={lastSignin} />
      {/* <h3>Choose salary year:</h3>
      {isSuccess && years?.length ? (
        <>
          {years?.map(({ year }) => (
            <Link key={year} to={`/registrations?year=${year}&month=${currentMonth}`}>
              <p>{year}</p>
            </Link>
          ))}
        </>
      ) : (
        <div>
          <p>No years found</p>
          <Button shape="round" onClick={() => handleCreateYear({ year: currentYear }, refetchYears)}>
            Create for {currentYear}
          </Button>
        </div>
      )} */}
    </div>
  )
}

export default Dashboard

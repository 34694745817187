import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Checkbox, Input, Button } from 'antd'

// Background: rgb(53, 58, 69);

export const BannerText = styled.h1`
  position: absolute;
  font-size: 96px;
  top: 25%;
  left: 35vw;
  color: darkcyan;
`
export const CenteredContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
export const CenteredContainerContent = styled.div`
  background: white;
  width: 100%;
  min-height: 50%;
  margin: 8px auto; //last one is height / 2
  border: 1px solid #000;

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  padding-bottom: 2rem;

  // Desktop:
  @media only screen and (min-width: 568px) {
    /* width: 80%; */
    margin: 2rem auto; //last one is height / 2
  }

  @media only screen and (min-width: 1024px) {
    width: 900px;
    border: 1px solid #000;
  }
`

export const RoundedInput = styled(Input)`
  border-radius: 25px;
`
export const StyledCheckbox = styled(Checkbox)`
  color: white;
  ${(props) =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
        border-radius: 25px;
      }
    `}
`

export const StyledSwitch = styled.div`
  .ant-switch-checked.ant-switch-checked.ant-switch-checked {
    background: rgb(180, 247, 200);
  }
`
export const StyledButton = styled(Button)`
  background: rgb(180, 247, 200);
  color: rgb(53, 58, 69);
  border-radius: 25px;
  width: 7rem;
`

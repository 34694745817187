import React, { useEffect, useState } from 'react'
import { Button, Divider, Tooltip } from 'antd'
import 'moment/locale/en-gb'
import { Files } from './Files'
import { useNavigate, useParams } from 'react-router-dom'
import { useMyday, useUserInfo } from '../../fetchHooks/useFetchHooks'
import { CenteredContainer, LoadingModal } from '../../styles'
import Navbar from '../../components/Navbar/Navbar'
import { Images } from './Images'
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { RichTextField, SensitiveTextField, formatDateOnly, waitForNSeconds } from '../../utils/utilFunctions'
import { EditMyday } from './EditMyday'
import HealthMetricGraphs from './HealthMetricGraphs'
import BlurScreenWhenInactive from '../../components/BlurScreenWhenInactive/BlurScreenWhenInactive'
import './MydayDetails.scss'

const MydayDetails = ({ mydayId, isExport = false }) => {
  const { id: paramId } = useParams()
  const id = isExport ? mydayId : paramId
  const { data: myday, isInitialLoading, error, refetch } = useMyday(id)
  const [hideText, setHideText] = useState(false)
  const [showEditMydayModal, setShowEditMydayModal] = useState(false)
  const { data: user } = useUserInfo()
  const navigate = useNavigate()

  useEffect(() => {
    !isExport && !hideText && waitForNSeconds(30, () => setHideText(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideText])

  if (isInitialLoading) return <LoadingModal>Loading...</LoadingModal>
  if (error) {
    const e = JSON.parse(error?.message)
    console.log('e: ', e)
    // console
    return (
      <LoadingModal>
        <p style={{ textAlign: 'center' }}>
          {e.message}: {e.error}
        </p>
      </LoadingModal>
    )
  }
  if (!myday) return <p>Error...</p>
  const { description } = myday
  const date = new Date(myday?.date)
  const onOk = (values) => {
    setShowEditMydayModal(false)
    refetch()
  }

  if (isExport)
    return (
      <div>
        <div className="myday-details-container">
          <div className="myday-details-title">
            <h1 style={{ marginBottom: 8, marginTop: 0 }}>{description}</h1>
            <h4 style={{ marginTop: 0 }}>{formatDateOnly(date)} </h4>
            {/* NOTE TEXT: */}
            <RichTextField text={myday?.note} />
          </div>
          {/* MEASUREMENTS: */}
          <div>
            {myday?.measurements?.length && (
              <>
                <h3>Measurements:</h3>
                <ol style={{ paddingInlineStart: 20 }}>
                  {myday?.measurements?.map?.((m) => (
                    <li key={m.id}>
                      {m.healthMetricType?.description}: <RichTextField text={m.value.toString()} />
                      {' ' + m.healthMetricType?.unit}
                    </li>
                  ))}
                </ol>
              </>
            )}
          </div>
        </div>
        {!!myday.file_count && <Images isExport={isExport} myday={myday} />}
        <Divider />
      </div>
    )

  return (
    <div>
      <Navbar />
      <BlurScreenWhenInactive />
      <Tooltip title="Go back">
        <Button size="large" icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} ghost className="back-button">
          back
        </Button>
      </Tooltip>

      <CenteredContainer>
        <div className="myday-details-container">
          <div className="myday-details-title">
            <div style={{ display: 'flex' }}>
              <h1 style={{ marginBottom: 8, marginTop: 0 }}>{description}</h1>
              {!isExport && (
                <Tooltip title="Edit Myday">
                  <Button
                    shape="round"
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => setShowEditMydayModal(myday)}
                    ghost
                    // style={{ alignItems: 'center' }}
                    className="edit-button"
                  />
                </Tooltip>
              )}
            </div>
            <h4 style={{ marginTop: 0 }}>{formatDateOnly(date)} </h4>
            {/* NOTE TEXT: */}
            <CenteredContainer className="tiptap" style={{ paddingLeft: 8, backgroundColor: 'rgba(255, 255, 255, 0.4)' }}>
              <RichTextField text={myday?.note} show={!hideText} onClick={() => setHideText((prev) => !prev)} />
              {/* <SensitiveRichTextField text={myday?.note} show={!hideText} onClick={() => setHideText((prev) => !prev)} /> */}
            </CenteredContainer>
          </div>
          {/* MEASUREMENTS: */}
          <div>
            {myday?.measurements?.length && (
              <>
                <h3>Measurements:</h3>
                <ol style={{ paddingInlineStart: 20 }}>
                  {myday?.measurements?.map?.((m) => (
                    <li key={m.id}>
                      {m.healthMetricType?.description}:{' '}
                      <SensitiveTextField
                        text={m.value.toString() + m.healthMetricType?.unit}
                        show={!hideText}
                        onClick={() => setHideText((prev) => !prev)}
                      />
                    </li>
                  ))}
                </ol>
              </>
            )}
          </div>
        </div>
      </CenteredContainer>
      {!!myday.file_count && (
        <CenteredContainer>
          <Images myday={myday} />
          <Files myday={myday} compact />
        </CenteredContainer>
      )}
      {/* Draw graphs for all measurements included in the current MyDay detail view: */}
      {!!myday?.measurements?.length && (
        <CenteredContainer>
          <h1>Graphs for todays measurements:</h1>
          <HealthMetricGraphs measurementList={myday?.measurements?.map(({ healthMetricType }) => healthMetricType.id)} />
        </CenteredContainer>
      )}
      <EditMyday
        showModal={showEditMydayModal}
        onClose={() => setShowEditMydayModal(false)}
        onOk={onOk}
        user={user}
        myday={showEditMydayModal}
      />
    </div>
  )
}

export default MydayDetails

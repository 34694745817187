import { useQueryClient } from '@tanstack/react-query'
import { envApiUrl } from '../../fetchHooks/useFetchHooks'
import { useState } from 'react'
import { message } from 'antd'
import axios from 'axios'
import { Button, FileInput, rem } from '@mantine/core'
import { IconUpload } from '@tabler/icons-react'

const UploadProfilePicture = () => {
  const queryClient = useQueryClient()
  const [selectedFile, setSelectedFile] = useState(null)

  const uploadProfilePicFetch = (file, onOk, setUploadProgress) => {
    console.log('handleUploadFile file', file)
    axios
      .post(envApiUrl + '/files/profile', file, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken',
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setUploadProgress?.(progress)
        }
      })
      .then((response) => {
        console.log('uploadProfilePicFetch : response: ', response)
        return response.data
      })
      .finally(() => {
        onOk?.()
        message.success('uploadProfilePicFetch: Successfully uploaded file')
      })
      .catch((err) => {
        console.error('uploadProfilePicFetch error: ', err.response?.data?.message, err)
        message.error(err.response?.data?.message)
      })
  }

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      // Make a POST request to your server's upload endpoint
      uploadProfilePicFetch(formData, () => queryClient.invalidateQueries(['userInfo']))
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <FileInput
        placeholder="Click to upload new picture"
        label="Your profile picture"
        value={selectedFile}
        onChange={setSelectedFile}
        icon={<IconUpload size={rem(14)} />}
        accept="image/png,image/jpeg"
      />

      <br />
      <Button onClick={handleUpload} variant="gradient" gradient={{ from: 'cyan', to: 'indigo' }}>
        Upload
      </Button>
    </div>
  )
}

export default UploadProfilePicture

import React, { useState } from 'react'
import { CenteredContent, CenteredText, StyledCancelButton, StyledMainTitle } from '../../styles'
import { RoundedInput, StyledSwitch, StyledButton, CenteredContainerWrapper, CenteredContainerContent } from './styles'
import { Form, Row, Col, Switch } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { handleSignIn } from '../../fetchHooks/useFetchHooks'
import { SignupModal } from './SignupModal'

const Login = () => {
  const [showSignup, setShowSignup] = useState(false)

  // return (
  //   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //     <div style={{ width: '200px', height: '200px', backgroundColor: 'red' }}></div>
  //   </div>
  // )
  return (
    <CenteredContainerWrapper>
      <CenteredContainerContent>
        {/* <div style={{ width: '500px', height: '500px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}> */}
        <Row justify={'center'} align="middle" gutter={[100, 100]}>
          {/* <Col span={12}><Image src={SalesPlanrLogo} preview={false} /> Salary Calculator</Col> */}
          <CenteredText>
            <StyledMainTitle>Welcome to MyDay</StyledMainTitle>
          </CenteredText>
        </Row>

        <Row justify={'center'} align="middle" gutter={[100, 100]}>
          {/* <Col span={12}><Image src={SalesPlanrLogo} preview={false} /> Salary Calculator</Col> */}
          <CenteredText>
            <h2>Log in:</h2>
          </CenteredText>
        </Row>
        <Row justify={'center'} align="middle" gutter={[100, 100]}>
          <Col span={12}>
            <CenteredContent>
              <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={handleSignIn}>
                <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                  <RoundedInput size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  // noStyle
                  style={{ marginBottom: 8 }}
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <RoundedInput
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                {/* {error && (
                  <CenteredContent>
                    <span style={{ color: 'red' }}>{error}</span>
                  </CenteredContent>
                )} */}
                <CenteredContent style={{ marginTop: 0 }}>
                  <Form.Item name="remember" noStyle style={{ margin: 0, padding: 0 }}>
                    <StyledSwitch>
                      <Switch />
                      <span style={{ marginLeft: '1rem' }}>Remember me</span>
                    </StyledSwitch>
                  </Form.Item>
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item> */}
                </CenteredContent>

                <CenteredContent>
                  {/* <Form.Item> */}
                  <div style={{ textAlign: 'center' }}>
                    <StyledButton shape="round" type="primary" htmlType="submit">
                      Log in
                    </StyledButton>
                    <p>or</p>
                    <StyledCancelButton size="small" shape="round" type="secondary" onClick={() => setShowSignup(true)}>
                      Sign up
                    </StyledCancelButton>
                  </div>
                  {/* </Form.Item> */}
                </CenteredContent>
              </Form>
            </CenteredContent>
          </Col>
        </Row>
      </CenteredContainerContent>
      <SignupModal show={showSignup} onCancel={() => setShowSignup(false)} onOk={(vals) => setShowSignup(false)} />
    </CenteredContainerWrapper>
  )
}

export default Login

import { useFilesOnly } from '../../fetchHooks/useFetchHooks'
import React, { useEffect } from 'react'
import 'video-react/dist/video-react.css'
import './Myday.scss'
import FileItem from './FileItem'

export const useEscapeKey = (callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        callback?.()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback]) // Empty dependency array ensures the effect is only run once
}

export const Files = ({ myday, compact = false, isEdit = false }) => {
  const { data: files, refetch, isLoading } = useFilesOnly(myday.id)
  if (!files?.length || isLoading) return <></>
  if (isEdit)
    return (
      <>
        <h3>Other files:</h3>

        <div style={{ display: 'block', width: '100%' }}>
          {files?.map((file) => (
            <FileItem file={file} refetch={refetch} compact={compact} key={file.id} isEdit={isEdit} />
          ))}
        </div>
      </>
    )
  return (
    <>
      <h3>Other files:</h3>

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(266px, 1fr))', gridColumnGap: 32, gridRowGap: 32 }}>
        {files?.map((file) => (
          <FileItem file={file} refetch={refetch} compact={compact} key={file.id} />
        ))}
      </div>
    </>
  )
}

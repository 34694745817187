import styled from '@emotion/styled'
import { Button, Input, InputNumber, Select } from 'antd'
import { Header } from 'antd/es/layout/layout'
import TextArea from 'antd/lib/input/TextArea'

export const AppWrapper = styled.div`
  margin: 0rem;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  min-height: 100%;
  /* background-color: darksand; */

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
  }
`

export const AppContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding: 8px;
  border-radius: 16px;
  margin-top: 32px;
  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    padding: 2rem;
  }
`

export const Grid = styled.div`
  margin: 0rem;

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    grid-auto-rows: minmax(100px, auto);
  }
`
export const LinkButton = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* width: 100%; */
  /* width: 40px; */
  /* 
  &:hover {
    width: auto;
    overflow: visible;
  } */
`

export const ContentScreenMiddleFixed = styled.div`
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
`
export const ContainerMain = styled.div`
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
`
export const LoadingModal = styled.div`
  position: fixed;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -150px; /* Negative half of width. */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 16px;
`

export const CenteredContainer = styled.div`
  /* position: fixed; */
  /* width: 300px;
  height: 200px; */
  /* top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -150px; Negative half of width. */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  margin: 4rem 0;
  padding: 3rem;
`

export const CenteredContent = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
`
export const CenteredText = styled.div`
  text-align: center;
`

export const TableContainer = styled.div`
  margin-bottom: 10rem; /* Negative half of height. */
`
export const SignupContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header header header header '
    'firstname firstname firstname firstname'
    'lastname lastname lastname lastname'
    'hourrate hourrate hourrate hourrate'
    'email email email email '
    'password password password password '
    'rate rate rate rate '
    'canceladd canceladd canceladd canceladd';

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  h2 {
    font-size: 36px;
  }
  p {
    font-size: 16px;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .create-user-header {
    grid-area: header;
    min-height: 100px;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    // display: flex;
    // align-items: center;
    padding: 2rem 0;

    h2 {
      font-family: FuturaMedium;
      font-size: 42px;
      margin: 0;
      margin-left: 2rem;
      width: 100%;
    }

    p {
      font-family: FuturaMedium;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }

  .create-user-firstname {
    grid-area: firstname;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-user-hour-rate {
    grid-area: hourrate;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .create-user-lastname {
    grid-area: lastname;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-user-password {
    grid-area: password;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .create-user-rate {
    grid-area: rate;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .create-user-email {
    grid-area: email;
    margin-right: 2rem;
    margin-left: 2rem;
    /* margin-bottom: 2rem; */
  }
`

export const StyledButton = styled(Button)`
  /* font-family: 'MontHeavy'; */
  font-family: FuturaMedium;
  font-size: 16px;
  background: rgb(180, 247, 200) !important;
  border: none;
  color: rgb(53, 58, 69) !important;
  /* font-weight: 500; */
  font-size: 16px;
  &:hover {
    border: none;
    background-color: rgba(180, 247, 200, 0.9);
  }
`
export const StyledCancelButton = styled(Button)`
  /* font-family: 'MontHeavy'; */
  /* color: white; */
  background: #eee;
  /* margin-right: 1rem; */
  /* font-weight: 500; */
  &:hover {
    border: none;
    background: #aaa;
  }
`

export const StyledInput = styled.input`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  padding: 8px;
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  font-size: 16px;
`
export const StyledTextArea = styled(TextArea)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  padding: 8px;
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  font-size: 16px;
`
export const StyledTextInput = styled(Input)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  /* padding: 8px; */
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  /* font-size: 16px; */
`
export const StyledTextInputNumber = styled(InputNumber)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  /* padding: 8px; */
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  width: 100%;
  /* width: 100%; */
  /* font-size: 16px; */
`
export const StyledSelect = styled(Select)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  padding: 8px;
  font-weight: 10;
  /* border: 1px solid rgb(53, 58, 69); */
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  /* font-size: 16px; */
  cursor: pointer;
  .ant-select-selector.ant-select-selector.ant-select-selector {
    /* border: 0px !important; */
    &:hover {
      cursor: pointer;
    }
  }
`

export const StyledInfoField = styled.p`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;
  font-size: 16px;

  background: white;
  padding: 8px;
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
`

export const StyledLabel = styled.p`
  font-size: 16px;
  @media only screen and (min-width: 1024px) {
    font-size: 24px;
    font-family: FuturaMedium;
    color: rgb(53, 58, 69);
  }
`
export const StyledLabelSpan = styled.span`
  font-size: 16px;
  @media only screen and (min-width: 1024px) {
    font-size: 20px;
    font-family: FuturaMedium;
    color: rgb(53, 58, 69);
  }
`
export const StyledMainTitle = styled.h1`
  font-size: 20px;
  @media only screen and (min-width: 1024px) {
    font-size: 72px;
    /* font-weight: 300; */
    font-family: FuturaBold;
    color: rgb(53, 58, 69);
  }
`

export const StyledLabelGreen = styled.span`
  font-size: 24px;
  color: rgb(180, 247, 200);
  text-decoration: underline;
  font-family: FuturaMedium;
`
export const StyledLabelWhite = styled.p`
  font-size: 16px;

  // Desktop:
  @media only screen and (min-width: 1024px) {
    font-size: 24px;
    font-family: FuturaMedium;
    color: white;
  }
`
export const StyledLabelWhiteBold = styled(StyledLabelWhite)`
  font-family: FuturaBold;
  color: white;
`
export const NavbarContainer = styled(Header)`
  @media only screen and (min-width: 1024px) {
    border-radius: 16px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
`

import React, { useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import 'moment/locale/en-gb'
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { deleteMyday, handleCreateMyday, handleUpdateMyday } from '../../fetchHooks/useFetchHooks'
import dayjs from 'dayjs'
import { ModalTitle } from './EditMyday'
import { MydayForm } from './MydayForm'
import { MyDayType } from './MyDayPage'
import UploadFilesDragger from '../../components/UploadFiles/UploadFilesDragger'

export const ModalButtons = ({ onOk, onCancel, okText = 'Add', cancelText = 'Cancel' }) => (
  <div className="modal-button-canceladd">
    {onCancel && (
      <Button shape="round" size="large" onClick={onCancel}>
        {cancelText}
      </Button>
    )}
    {onOk && (
      <Button shape="round" size="large" onClick={onOk} icon={okText === 'Add' ? <PlusCircleOutlined /> : <SaveOutlined />}>
        {okText}
      </Button>
    )}
  </div>
)

export const CreateMyday = ({ onOk, onClose, showModal = true, user, type = MyDayType.MEASUREMENT }) => {
  const [form] = Form.useForm()
  const [newMyday, setNewMyday] = useState()

  useEffect(() => {
    const defaultDate = new Date(dayjs())
    if (showModal && !newMyday) {
      handleCreateMyday(
        {
          userId: user.id,
          year: defaultDate.getFullYear(),
          month: defaultDate.getMonth() + 1,
          day: defaultDate.getDate()
        },
        user
      ).then((res) => {
        console.log('CreateMyday  res', res)
        setNewMyday({ ...res })
      })
    }
  }, [showModal, newMyday, setNewMyday])

  useEffect(() => {
    showModal && form.setFieldsValue({ date: dayjs() })
  }, [showModal, form])

  const onFinish = (values) => {
    const d = new Date(values.date)
    const data = {
      ...values,
      userId: user.id,
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    }
    handleUpdateMyday(data, newMyday, onOk)
  }

  const onCancel = () => {
    console.log('onCancel')
    newMyday.id && deleteMyday(newMyday.id, () => setNewMyday(null))
    onClose?.()
  }
  return (
    <Modal
      open={showModal}
      id={'create-myday'}
      onCancel={onCancel}
      styles={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="modalStyle"
      width="1280px"
      footer={null}
      centered
      destroyOnClose
    >
      <ModalTitle title={'Create Myday'} />
      <MydayForm onFinish={onFinish} form={form} onCancel={onCancel} myday={newMyday} type={type}>
        <UploadFilesDragger myday={newMyday} onOk={onOk} />
      </MydayForm>
    </Modal>
  )
}

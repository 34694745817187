import { useUserInfo } from '../../fetchHooks/useFetchHooks'
import { AppContainer } from '../../styles'
import Navbar from '../../components/Navbar/Navbar'
import UserInputDebounced from './UserInputDebounced'
import { MyImage } from '../../components/MyImage/MyImage'
import UploadProfilePicture from './UploadProfilePicture'

const ProfilePage = () => {
  const { data: user, isLoading } = useUserInfo()
  console.log('ProfilePage user: ', user)
  if (!user && isLoading) return 'Loading...'
  return (
    <>
      <Navbar />
      <AppContainer>
        <h1>Profile page</h1>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '2rem' }}>
            <MyImage src={user?.settings?.profilePicURL} />
            <UploadProfilePicture />
          </div>
          <div style={{ margin: '2rem' }}>
            <UserInputDebounced userId={user.id} label="Firstname" propertyName="firstname" value={user.firstname} />
            <UserInputDebounced userId={user.id} label="Lastname" propertyName="lastname" value={user.lastname} />
            <UserInputDebounced userId={user.id} label="Hour Rate" propertyName="hourRate" value={user.hourRate} />
          </div>
        </div>
      </AppContainer>
    </>
  )
}

export default ProfilePage

import { Cascader, TimePicker } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import format from 'date-fns/format'
import getHours from 'date-fns/getHours'
import { useQueryClient } from '@tanstack/react-query'
import { updateDay } from '../../fetchHooks/useFetchHooks'
import './Day.scss'
import dayjs from 'dayjs'
import { getMinutes, isBefore, sub } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import isToday from 'date-fns/isToday'
import RegistrationInputDebounced from './RegistrationInputDebounced'
import dayjsPluginUTC from 'dayjs-plugin-utc'

dayjs.extend(dayjsPluginUTC)

export const formatDate = (d) => d.utc().format('YYYY-MM-DD HH:mm:ssZ')
// export const formatDate = (d) => d.utc().format('YYYY-MM-DD HH:mm:ssZ')

export const formatCurrency = (value, options) => {
  if (typeof value !== 'number') return value
  const formatCurrencyValue = (value, options) => {
    const isInt = (value) => value % 1 === 0
    const { decimalScale = 2, decimalSeparator = ',', thousandSeparator = ' ' } = options ?? {}
    return (isInt(value) ? value.toString() : value.toFixed(decimalScale))
      .toString()
      .replace('.', decimalSeparator)
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
  }
  return formatCurrencyValue(value, options) + ',-'
}

const disabledDateTime = () => {
  function range(start, end) {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  return {
    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7], // () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(0, 0),
    disabledSeconds: () => range(0, 0)
  }
}

export const stringToDateUTC = (dateString) => parseInt(format(new Date(dateString), 'TT'))
export const forceTwoDigits = (n) => n.toString().padStart(2, 0)
export const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz })
// const stringToDaysJsFormat = (
//   date // 2023-01-01T08:00:00.000Z
// ) => (date ? format(date, 'yyyy-MM-ddThh:mm:ss.SSS') : '')
const stringToDaysJsFormat = (date) =>
  date
    ? `${forceTwoDigits(getHours(new Date(date.replace(/Z/g, ''))))}:${forceTwoDigits(getMinutes(new Date(date.replace(/Z/g, ''))))}:00`
    : ''

const Day = ({ date, isExport = false }) => {
  const {
    // year,
    // month,
    day,
    lunchTime,
    overtimeType,
    // ordinaryHours,
    isWorkingDay,
    overtimeHours,
    // avspaseres,
    totalHours,
    // bruttoAmount,
    // nettoAmount,
    // currentDate,
    ordinaryhoursdecimal,
    flexiTimeHours,
    // weekNumber,
    weekDay,
    id,
    description,
    earned
  } = date
  const isFlexiTime = overtimeType === 'flex'
  // const [descriptionInput, setDescriptionInput] = useState(description)
  const startTime = stringToDaysJsFormat(date.startTime)
  const currentDate = new Date(date.currentDate)
  const endTime = stringToDaysJsFormat(date.endTime)

  const opt = [
    {
      value: '00:30:00',
      label: '30 min'
    },
    {
      value: '01:00:00',
      label: '60 min'
    }
  ]

  const queryClient = useQueryClient()
  const timeFormat = 'HH:mm'

  const handleChangeRegistration = (data = {}) =>
    updateDay({ ...data, id }, () => {
      console.log('Running invalidations')
      queryClient.invalidateQueries({ queryKey: ['useRegistrations'] })
      queryClient.invalidateQueries({ queryKey: ['useYearStats'] })
    })

  return (
    <div
      className={`day-wrapper ${isExport ? 'day-wrapper-export' : ''}`}
      style={{
        color: !isWorkingDay ? 'red' : 'black',
        ...(!isExport && isToday(currentDate) && { border: '2px dotted lightgreen' }),
        background: isBefore(sub(new Date(), { days: 1 }), currentDate) && !isExport ? 'white' : '#eee'
      }}
    >
      <div className="date">{day}</div>
      <div className="weekday">{weekDay}</div>
      {!isExport && (
        <div className="workday">
          <Checkbox
            checked={isWorkingDay}
            onChange={() => {
              handleChangeRegistration({
                isWorkingDay: !isWorkingDay
              })
            }}
          />
        </div>
      )}

      <div className="start">
        <TimePicker
          disabledTime={disabledDateTime}
          minuteStep={30}
          format={timeFormat}
          defaultValue={startTime ? dayjs(startTime, 'HH:mm') : dayjs('08:00', 'HH:mm')}
          changeOnBlur
          onSelect={(dateAsDayJs) =>
            handleChangeRegistration({
              startTime: format(new Date(date.endTime), "yyyy'-'MM'-'dd") + ' ' + dateAsDayJs.format('HH:mm')
            })
          }
          showTime={{
            initialValue: dayjs(startTime, 'HH:mm'),
            minuteStep: 30,
            format: 'HH:mm',
            allowClear: false,
            hideDisabledOptions: true,
            showNow: false
          }}
        />
      </div>
      <div className="stop">
        <TimePicker
          disabledTime={disabledDateTime}
          minuteStep={30}
          format={timeFormat}
          defaultValue={endTime ? dayjs(endTime, 'HH:mm') : dayjs('16:00', 'HH:mm')}
          changeOnBlur
          onSelect={(dateAsDayJs) => {
            handleChangeRegistration({
              endTime: format(new Date(date.endTime), "yyyy'-'MM'-'dd") + ' ' + dateAsDayJs.format('HH:mm')
            })
          }}
          showTime={{
            minuteStep: 30,
            format: 'HH:mm',
            allowClear: false,
            hideDisabledOptions: true,
            showNow: false
          }}
        />
      </div>
      <Cascader
        options={opt}
        value={lunchTime}
        className="lunch"
        onChange={(e) =>
          handleChangeRegistration({
            lunchTime: e[0]
          })
        }
        placeholder="Please select"
      />

      {!isExport && (
        <div className="flexTime">
          <Checkbox
            checked={isFlexiTime}
            onChange={() => {
              handleChangeRegistration({
                overtimeType: isFlexiTime ? 'Paid' : 'flex'
              })
            }}
          />
        </div>
      )}

      {/* <div className="overtime_type">{overtimeType}</div> */}
      <div className="ord_hours">{ordinaryhoursdecimal}</div>
      <div className="overtime_hours">
        {isExport ? overtimeHours : overtimeType === 'flex' ? `${flexiTimeHours} (flex)` : `${overtimeHours}`}
      </div>
      {/* <div className="avspaseres">{avspaseres}</div> */}
      <div className="total">{totalHours}</div>
      <div className="brutto">{formatCurrency(earned)}</div>
      {!isExport && (
        <div className="description">
          <RegistrationInputDebounced registrationId={id} label="Note" propertyName="description" value={description} textArea />
        </div>
      )}
    </div>
  )
}

export default Day

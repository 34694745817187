import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'

import { ModalButtons } from '../MyDayPage/CreateMyday'
import { openNotification } from '../LoginPage/SignupModal'
import { ModalTitle } from '../MyDayPage/EditMyday'
import { handleCreateHealthmetricType } from '../../fetchHooks/useFetchHooks'

export const CreateHealthMetricModal = ({ show, onCancel, onOk }) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form && form.resetFields()
  }, [form])

  if (!show) return <></>

  return (
    <Modal
      open={show}
      styles={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="create-healthmetrictype-modal"
      onCancel={onCancel}
      footer={null}
      onOk={() => console.log('Validate Failed:')}
      forceRender
    >
      <ModalTitle title="Create healthmetrictype" />
      <Form
        form={form}
        name="create-healthmetrictype"
        // onFinish={onFinish}
        className="create-healthmetrictype-form"
        initialValues={{ website: '', healthmetrictype: '' }}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label={<p>Name</p>}
          rules={[
            {
              required: true,
              message: 'Please input website!'
            }
          ]}
        >
          <Input autoComplete="off" list="autocompleteOff" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please input description'
            }
          ]}
        >
          <Input autoComplete="off" list="autocompleteOff" />
        </Form.Item>
        <Form.Item
          name="unit"
          label="Measurement unit"
          rules={[
            {
              required: true,
              message: 'Please input unit'
            }
          ]}
        >
          <Input autoComplete="off" list="autocompleteOff" />
        </Form.Item>
        <Form.Item
          name="ref_lower"
          label="Reference low"
          rules={[
            {
              message: 'Please input reference low'
            }
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="ref_upper"
          label="Reference upper"
          rules={[
            {
              message: 'Please input reference upper'
            }
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item name="comment" label="Comment">
          <Input.TextArea showCount autoSize={{ minRows: 4, maxRows: 60 }} />
        </Form.Item>
        <ModalButtons
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                const vals = {
                  ...values
                }
                handleCreateHealthmetricType(vals, () => {
                  openNotification('Success', `Added ${vals.description} Healthmetric Type.`)
                  onOk?.()
                })
                console.log('Create Myhealthmetrictype', vals)
                form.resetFields()
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
          onCancel={onCancel}
          okText="Create"
        />
      </Form>
    </Modal>
  )
}

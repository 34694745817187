import React, { useRef } from 'react'
import Modal from 'antd/lib/modal/Modal'
import ReactToPrint from 'react-to-print'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'

export const MonthExport = ({ showModal = false, onCancel, children }) => {
  const componentRef = useRef()
  return (
    <Modal
      open={showModal}
      id="MonthExport"
      onCancel={onCancel}
      styles={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="modalStyle"
      width="auto"
      footer={null}
      centered
      destroyOnClose
    >
      <div style={{ textAlign: 'right', marginRight: '4rem' }}>
        <ReactToPrint style={{}} trigger={() => <Button icon={<PrinterOutlined />}>Print</Button>} content={() => componentRef.current} />
      </div>
      <div ref={componentRef} style={{ margin: '2rem' }}>
        {children}
      </div>
    </Modal>
  )
}

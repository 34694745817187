import { useState } from 'react'
import './styles.scss'
import { Modal } from 'antd'
import { useEscapeKey } from '../../pages/MyDayPage/Files'
import { AspectRatio, Image } from '@mantine/core'
import { CenteredText } from '../../styles'

export const CenteredModal = ({ visible, onCancel, children, src }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      centered
      getContainer={false}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      wrapClassName="centered-modal"
      styles={{ padding: 0 }}
    >
      <div className="image-container">
        <div className={`image-wrapper ${imageLoaded ? 'loaded' : ''}`}>
          <div className="image-content">
            <img src={src} alt="Modal Image" onLoad={handleImageLoad} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const MyImage = ({ src, alt, compact = false, onClick }) => {
  return (
    <>
      <div
        className="my-image-container"
        style={{
          height: compact ? 150 : 250,
          width: compact ? 150 : '250px'
          //   objectFit: 'cover'
        }}
      >
        <img src={src} onClick={onClick} alt={alt} style={{ maxWidth: '100%' }} />
      </div>
    </>
  )
}

export const MyImageModal = ({ src, compact = false, style, name, description }) => {
  const [showModal, setShowModal] = useState(false)
  const [clickedImage, setClickedImage] = useState({ src: undefined, alt: undefined })
  return (
    <>
      <div
        style={{
          ...style
        }}
        onClick={() => {
          setShowModal(true)
          setClickedImage({ src, description })
        }}
      >
        <AspectRatio
          title={name}
          ratio={720 / 1080}
          maw={compact ? 150 : 266}
          mah={compact ? 150 : 266}
          mx="auto"
          style={{ borderRadius: 16 }}
        >
          <img src={src} alt={description} style={{ ...style, borderRadius: 16 }} />
        </AspectRatio>
        <CenteredText>{description}</CenteredText>
      </div>
      {showModal && <ImageModal clickedImage={clickedImage} onClose={() => setShowModal(false)} />}
    </>
  )
}

export const MyImagePickerItem = ({ src, alt, compact = false, style, onClick, onClose }) => {
  return (
    <>
      <div
        className="picker"
        style={{
          ...style
        }}
        onClick={() => {
          onClick?.({ src, alt })
          onClose?.()
        }}
      >
        <AspectRatio ratio={720 / 1080} maw={compact ? 150 : 250} mah={compact ? 150 : 250} mx="auto" style={{ borderRadius: 16 }}>
          <img src={src} alt={alt} style={{ ...style, borderRadius: 16 }} />
        </AspectRatio>
      </div>
    </>
  )
}

export const ImageModal = ({ clickedImage, onClose, onClick }) => {
  const { src, alt } = clickedImage

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content">
        <img
          src={src}
          alt={alt}
          onClick={(event) => {
            event.stopPropagation()
            onClick()
          }}
        />
      </div>
    </div>
  )
}

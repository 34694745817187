import { Input } from 'antd'
import { updateFile } from '../../fetchHooks/useFetchHooks'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export const FileUpdateDebounced = ({ label, propertyName, fileId, value, style, isNumber = false }) => {
  const [inputValue, setInputValue] = useState(value)
  const [debounceTimer, setDebounceTimer] = useState(null)
  const queryClient = useQueryClient()

  const isValueUpdated = inputValue === value

  const handleInputChange = (event) => {
    const value = event.target.value
    isNumber ? !isNaN(value) && setInputValue(value) : setInputValue(value)

    // Clear previous timer if any
    clearTimeout(debounceTimer)

    // Set a new timer to send the PUT request after 2 seconds
    const newDebounceTimer = setTimeout(() => {
      updateFile({ [propertyName]: value, id: fileId }, () => {
        queryClient.invalidateQueries(['useImages'])
        queryClient.invalidateQueries(['useAllFiles'])
        queryClient.invalidateQueries(['useFilesOnly'])
      })
    }, 2000)

    setDebounceTimer(newDebounceTimer)
  }

  useEffect(() => {
    // Clear the timer when the component unmounts or when inputValue changes
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [debounceTimer, inputValue])

  return (
    <Input
      placeholder={label}
      value={inputValue}
      addonBefore={label + ':'}
      status={!isValueUpdated && 'warning'}
      onChange={handleInputChange}
      style={style}
    />
  )
}
export default FileUpdateDebounced

import { useHealthmetrictypesWithMeasurements } from '../../fetchHooks/useFetchHooks'
import { MydayStatsGraph } from './MydayStatsGraph'

const HealthMetricGraphs = ({ selectedGraph, measurementList = false }) => {
  const { data: healthmetrictypes, isInitialLoading } = useHealthmetrictypesWithMeasurements()
  if (isInitialLoading) return <p>Loading...</p>
  if (!!measurementList?.length) {
    return (
      <div className="myday-graphs-wrapper">
        {measurementList?.map((measurementId) => (
          <MydayStatsGraph healthMetric={healthmetrictypes.find(({ id }) => id === measurementId)} key={measurementId} />
        ))}
      </div>
    )
  }
  if (!selectedGraph) return <></>
  return (
    <div className="myday-graphs-wrapper">
      {selectedGraph === 'all' ? (
        healthmetrictypes?.map((item) => <MydayStatsGraph healthMetric={item} key={item.id} />)
      ) : (
        <MydayStatsGraph healthMetric={healthmetrictypes.find(({ id }) => id === selectedGraph)} />
      )}
    </div>
  )
}
export default HealthMetricGraphs

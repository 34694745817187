import React from 'react'
import { Avatar, Layout, Menu, theme, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useModuleAccessCheck, useUserInfo } from '../../fetchHooks/useFetchHooks'
import { UserOutlined } from '@ant-design/icons'
import './Navbar.scss'
const { Text } = Typography
export const isMobile = window.innerWidth < 500

const Navbar = ({ year, month, hidden = false }) => {
  const { data: user } = useUserInfo()
  const userHasServiceMydays = useModuleAccessCheck('myday') // !!user?.services?.find?.((item) => item === 'myday')
  const userHasServicePasswords = useModuleAccessCheck('passwords') //!!user?.services?.find?.((item) => item === 'passwords')
  const userHasServiceSalary = useModuleAccessCheck('salary') //!!user?.services?.find?.((item) => item === 'salary')
  const userIsMaster = user?.role === 'MASTER'

  const handleSignOut = () => {
    window.location.href = '/signout'
  }

  if (hidden) return

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const menuItems = () => [
    {
      key: -1,
      label: (
        <Link to={`/`}>
          <span className="logo">MyDay</span>
        </Link>
      )
    },
    userHasServiceMydays && {
      key: 0,
      label: <Link to={`/myday`}>{'MyDay page'}</Link>
    },
    userHasServiceSalary && {
      key: 1,
      label: <Link to={`/registrations?year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`}>{'Salary calc'}</Link>
    },
    userHasServiceSalary && {
      key: 2,
      label: <Link to={`/year?year=${new Date().getFullYear()}`}>{'Yearly stats'}</Link>
    },
    userHasServicePasswords && {
      key: 3,
      label: <Link to={`/mypasswords`}>{'My passwords'}</Link>
    },
    userIsMaster && {
      key: 4,
      label: <Link to={`/admin`}>{'Admin'}</Link>
    },

    {
      label: user?.username ? (
        <>
          <Text style={{ color: 'inherit' }}>
            {user.firstname} ({user.role})
          </Text>
          <Link to="/profile">
            <Avatar
              size={50}
              style={{ marginLeft: 16, marginTop: '-5px', backgroundColor: 'rgba(53, 58, 69, 1)', outline: '3px solid rgb(180, 247, 200)' }}
              src={user.settings?.profilePicURL}
              icon={<UserOutlined />}
            />
          </Link>
        </>
      ) : (
        <span>Not logged in</span>
      )
    },
    {
      label: (
        <Link onClick={handleSignOut} style={{ color: 'inherit' }}>
          Sign out
        </Link>
      )
    }
  ]
  return (
    <Layout style={{ background: 'transparent' }}>
      {/* <NavbarContainer> */}
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[`${month}`]}
        items={menuItems()}
        className="navbar"
        style={{ borderRadius: 8, height: 64, display: 'flex', alignItems: 'center' }}
      />
      {/* </NavbarContainer> */}
    </Layout>
  )
}

export default Navbar

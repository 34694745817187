import { Button, Form, Input, Select, Space } from 'antd'
import { useHealthmetrictypes } from '../../fetchHooks/useFetchHooks'
import { useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
const { Option } = Select

const AddMetricItem = ({ field, healthmetrictypes, remove }) => {
  console.log(healthmetrictypes)
  const [selectedValue, setSelectedValue] = useState(null)
  const customFilterOption = (inputValue, option) =>
    option.children?.props?.children?.toLowerCase?.().indexOf(inputValue.toLowerCase()) >= 0
  return (
    <Space key={field.key} align="baseline">
      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.id !== curValues.id || prevValues.value !== curValues.value}>
        {() => (
          <Form.Item
            {...field}
            label="Measurement type"
            name={[field.name, 'healthmetrictypeId']}
            rules={[
              {
                required: true,
                message: 'Missing measurement'
              }
            ]}
          >
            <Select
              style={{
                width: 300
              }}
              showSearch
              onChange={(id) => {
                const selectedItem = healthmetrictypes.find((item) => item.id === id)
                setSelectedValue(selectedItem)
              }}
              filterOption={customFilterOption} // Set the custom filter function
            >
              <option>Select a Health Metric</option>
              {healthmetrictypes.map((item) => (
                <Option key={item.id} value={item.id} on>
                  <span>{item.description}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        {...field}
        label={`Value`}
        type="number"
        name={[field.name, 'value']}
        rules={[
          {
            required: true,
            message: 'Missing value'
          }
        ]}
      >
        <Input type="number" suffix={selectedValue?.unit} />
      </Form.Item>
      <MinusCircleOutlined onClick={() => remove(field.name)} />
    </Space>
  )
}

export const AddHealthMetricForm = ({ form, onFinish, onCancel, isEdit }) => {
  const { data: healthmetrictypes } = useHealthmetrictypes()

  if (!healthmetrictypes) return <></>
  return (
    <Form.List name="measurements" form={form}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <AddMetricItem key={field.id} field={field} healthmetrictypes={healthmetrictypes} remove={remove} />
          ))}

          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add measurement
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

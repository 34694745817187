import { useState } from 'react'
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd'
import { deleteCredential, useModuleAccessCheck, useMyPasswords, useUserInfo } from '../../fetchHooks/useFetchHooks'
import './MyPasswords.scss'
import Navbar from '../../components/Navbar/Navbar'
import { AppContainer } from '../../styles'
import { useNavigate } from 'react-router-dom'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { CreatePasswordModal } from './CreatePasswordModal'
import { EditPasswordModal } from './EditPasswordModal'
import { CopyToClipboardButton, addHttps, waitForNSeconds } from '../../utils/utilFunctions'

const SensitiveInfoField = ({ text }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          !show && waitForNSeconds(3, () => setShow(false))
          setShow((prev) => !prev)
        }}
      >
        {show ? text : '****************'}
      </span>
      <CopyToClipboardButton text={text} />
    </>
  )
}

export const ModuleAccessDenied = () => {
  return (
    <div>
      <Navbar />
      <AppContainer>
        <p>You dont have access to this module.</p>
      </AppContainer>
    </div>
  )
}

export const MyPasswordsTable = ({ passwords, setShowEditMydayModal, refetch }) => {
  const navigate = useNavigate()
  const columns = !passwords
    ? []
    : [
        {
          title: 'Website/Service',
          dataIndex: 'website',
          sorter: (a, b) => a.website.localeCompare(b.website),
          key: 'website',
          render: (website, item) => {
            const url = addHttps(website)
            if (url.startsWith('https://'))
              return (
                <a href={url} target="_blank" rel="noreferrer">
                  {' '}
                  {website || '-'}
                </a>
              )
            else return <p>{url}</p>
          },
          responsive: ['xs', 'sm', 'lg']
        },

        {
          title: 'Username',
          dataIndex: 'username',
          sorter: (a, b) => a.username.localeCompare(b.username),
          key: 'username',
          // render: (username, item) => <CopyToClipboardButton text={username || '-'} />,

          render: (username, item) => <p>{<SensitiveInfoField text={username} /> || '-'}</p>,
          responsive: ['xs', 'sm', 'lg']
        },

        {
          title: 'Password',
          dataIndex: 'password',
          sorter: (a, b) => a.password.localeCompare(b.password),
          key: 'password',
          render: (password, item) => <SensitiveInfoField text={password} />,
          responsive: ['xs', 'sm', 'lg']
        },
        {
          title: 'Comment',
          dataIndex: 'comment',
          sorter: (a, b) => a.comment.localeCompare(b.comment),
          key: 'comment',
          render: (comment, item) => <p>{<SensitiveInfoField text={comment} /> || '-'}</p>,
          responsive: ['xs', 'sm', 'lg']
        },
        {
          title: 'Shared with',
          dataIndex: 'users',
          // sorter: (a, b) => a.comment.localeCompare(b.comment),
          key: 'users',
          render: (users, item) => (
            <p>
              {users?.map?.((item) => (
                <>
                  {item}
                  <br />
                </>
              )) || '-'}
            </p>
          ),
          responsive: ['xs', 'sm', 'lg']
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, item) => (
            <Space.Compact size="middle">
              <Tooltip title="Edit">
                <Button
                  shape="round"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => setShowEditMydayModal?.(item)}
                  className="edit-button"
                />
              </Tooltip>
              <Tooltip title="Delete Myday">
                <Popconfirm
                  title="Are you sure to delete this item?"
                  onConfirm={() => deleteCredential(item.id, refetch)}
                  onCancel={() => console.log('Canceled')}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="edit-button" shape="round" size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Space.Compact>
          ),
          responsive: ['xs', 'sm', 'lg']
        }
      ]

  return <Table dataSource={passwords} columns={columns} rowKey="id" style={{ marginBottom: 16 }} />
}

const MyPasswords = () => {
  const { data: user } = useUserInfo()
  const { data: passwords, refetch, isSuccess } = useMyPasswords()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditMydayModal, setShowEditMydayModal] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const hasModuleAccess = useModuleAccessCheck('passwords')

  if (!isSuccess) return <>Loading...</>
  if (hasModuleAccess)
    return (
      <div>
        <Navbar />
        <AppContainer>
          {user?.firstname && <h2>{user?.firstname}'s Passwords</h2>}
          {isSuccess ? (
            <div>
              Search: <input value={searchInput} type="text" onChange={(e) => setSearchInput(e.target.value)} />
              <MyPasswordsTable
                passwords={passwords.filter?.(
                  ({ website, comment }) =>
                    website.toLowerCase().includes(searchInput.toLowerCase()) || comment.toLowerCase().includes(searchInput.toLowerCase())
                )}
                refetch={refetch}
                setShowEditMydayModal={setShowEditMydayModal}
              />
              <Button onClick={() => setShowCreateModal(true)}>Create new</Button>
            </div>
          ) : (
            <p>Loading...</p>
          )}

          <CreatePasswordModal
            show={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            onOk={() => {
              setShowCreateModal(false)
              refetch()
            }}
          />
          <EditPasswordModal
            show={showEditMydayModal}
            onCancel={() => setShowEditMydayModal(false)}
            onOk={() => {
              setShowEditMydayModal(false)
              refetch()
            }}
            credential={showEditMydayModal}
          />
        </AppContainer>
      </div>
    )
  return <ModuleAccessDenied />
}

export default MyPasswords

import React, { useEffect } from 'react'
import { Form, Divider } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import 'moment/locale/en-gb'
import { handleUpdateMyday } from '../../fetchHooks/useFetchHooks'
import dayjs from 'dayjs'
import UploadFilesDragger from '../../components/UploadFiles/UploadFilesDragger'
import { MydayForm } from './MydayForm'

export const ModalTitle = ({ title }) => (
  <>
    <h1
      style={{
        textAlign: 'center',
        paddingBottom: '2rem',
        paddingTop: '2rem',
        background: 'rgba(147, 188, 255, 0.3)',
        borderRadius: 16
      }}
    >
      {title}
    </h1>
    <Divider />
  </>
)

export const EditMyday = ({ onOk, onClose, showModal, user, myday = false }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    showModal && form.setFieldsValue({ date: dayjs() })
  }, [showModal, form])

  useEffect(() => {
    showModal &&
      form?.setFieldsValue?.({
        ...myday,
        date: dayjs(myday.date),
        type: myday.type,
        measurements: []
      })
  }, [form, myday, showModal])

  if (!showModal) return <></>

  const onFinish = (values) => {
    const d = new Date(values.date)
    const data = {
      ...values,
      userId: user.id,
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    }
    handleUpdateMyday(data, myday, onOk)
  }

  const onCancel = () => {
    onClose?.()
  }
  return (
    <Modal
      open={showModal}
      id={'edit-myday'}
      onCancel={onCancel}
      styles={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="modalStyle"
      width="1280px"
      footer={null}
      centered
      destroyOnClose
    >
      <ModalTitle title={'Edit Myday'} />
      <MydayForm onFinish={onFinish} form={form} onCancel={onCancel} myday={myday} isEdit>
        <UploadFilesDragger myday={myday} onOk={onOk} />
      </MydayForm>
    </Modal>
  )
}

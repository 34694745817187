import { Input } from 'antd'
import { updateHealthmetricType } from '../../fetchHooks/useFetchHooks'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import TextArea from 'antd/es/input/TextArea'

export const HealthMetricInputDebounced = ({ label, propertyName, healthmetricId, value, style, isNumber = false, textArea = false }) => {
  const [inputValue, setInputValue] = useState(value)
  const [debounceTimer, setDebounceTimer] = useState(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    setInputValue(value)
  }, [value, setInputValue])

  useEffect(() => {
    // Clear the timer when the component unmounts or when inputValue changes
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [debounceTimer, inputValue])

  if (!propertyName || !healthmetricId) return <p>Err: {label}</p>

  const isValueUpdated = inputValue.valueOf() === value.valueOf()

  const handleInputChange = (event) => {
    const value = event.target.value
    isNumber ? !isNaN(value) && setInputValue(value) : setInputValue(value)

    // Clear previous timer if any
    clearTimeout(debounceTimer)

    // Set a new timer to send the PUT request after 2 seconds
    const newDebounceTimer = setTimeout(() => {
      updateHealthmetricType({ [propertyName]: value, id: healthmetricId }, () => {
        queryClient.invalidateQueries(['useHealthmetrictypes'])
      })
    }, 2000)

    setDebounceTimer(newDebounceTimer)
  }

  if (textArea)
    return (
      <div>
        <p>{label + ':'}</p>
        <TextArea
          placeholder={label}
          value={inputValue}
          status={!isValueUpdated && 'warning'}
          autoSize={{ minRows: 20, maxRows: 600 }}
          onChange={handleInputChange}
          style={{ ...style, width: '100%' }}
        />
      </div>
    )
  return (
    <div>
      <Input
        placeholder={label}
        value={inputValue}
        addonBefore={label + ':'}
        status={!isValueUpdated && 'warning'}
        onChange={handleInputChange}
        style={style}
      />
    </div>
  )
}
export default HealthMetricInputDebounced

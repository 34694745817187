import { useEffect, useState } from 'react'
import { Button, Select, Tabs } from 'antd'

import { useHealthmetrictypesWithMeasurements, useMydays, useUserInfo } from '../../fetchHooks/useFetchHooks'
import { CreateMyday } from './CreateMyday'
import './Myday.scss'
import Navbar from '../../components/Navbar/Navbar'
import { AppContainer } from '../../styles'
import { EditMyday } from './EditMyday'
import { useQueryClient } from '@tanstack/react-query'
import { ShowAllMydays } from './ShowAllMydays'
import HealthMetricGraphs from './HealthMetricGraphs.js'
import BlurScreenWhenInactive from '../../components/BlurScreenWhenInactive/BlurScreenWhenInactive'
import { DiaryTable } from './DiaryTable'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { MeasurementsTable } from './MeasurementsTable'
import { NotesTable } from './NotesTable'
import { HealthRelatedTable } from './HealthRelatedTable.js'
import { ExperiencesTable } from './ExperiencesTable.js'
const { Option } = Select

export const colors = ['#69d2e7', '#a7dbd8', '#e0e4cc', '#f38630', '#fa6900', '#fe4365', '#fc9d9a', '#f9cdad', '#c8c8a9', '#83af9b']

export const MyDayType = {
  DIARY: 'diary',
  MEASUREMENT: 'measurement',
  HEALTH_RELATED: 'health-related',
  NOTE: 'note',
  EXPERIENCE: 'experience',
  ALL: 'all'
}

const MyDayPage = () => {
  const { tab } = useParams() // Get the 'tab' query parameter from the URL

  const { data: user, isLoading: isLoadingUser } = useUserInfo()
  const { data: healthmetrictypes, isInitialLoading } = useHealthmetrictypesWithMeasurements()
  const { data: mydays, refetch, isSuccess } = useMydays()
  const [showGraphSelector, setShowGraphSelector] = useState('')
  const [showCreateMydayModal, setShowCreateMydayModal] = useState(false)
  const [activeKey, setActiveKey] = useState(tab || MyDayType.EXPERIENCE) // Set the initial active tab based on the query parameter
  const [showEditMydayModal, setShowEditMydayModal] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation() // Get the current location object

  useEffect(() => {
    const newTab = new URLSearchParams(location.search).get('tab')
    if (newTab && newTab !== activeKey) {
      setActiveKey(newTab)
    }
  }, [activeKey, tab])

  if (!isSuccess) return <>Loading...</>

  const handleCreateMyday = (values) => {
    setShowCreateMydayModal(false)
    refetch()
    console.log('Refetcing MyDays')
    queryClient.invalidateQueries(['useHealthmetrictypesWithMeasurements'])
  }

  const onOk = (values) => {
    setShowEditMydayModal(false)
    refetch()
    queryClient.invalidateQueries(['useHealthmetrictypesWithMeasurements'])
    console.log('Refetcing MyDays')
  }

  const handleTabChange = (key) => {
    // Use history.push to navigate to a new URL with the selected tab
    navigate(`?tab=${key}`, { tab: key })
    setActiveKey(key)
  }

  return (
    <div>
      <Navbar />
      <BlurScreenWhenInactive />
      <AppContainer>
        <h2>{!!!isLoadingUser ? user?.firstname + "'s" : 'Your '} Myday page</h2>
        {isSuccess ? (
          <div>
            <Tabs
              activeKey={activeKey}
              onChange={handleTabChange}
              items={[
                {
                  label: 'Experience',
                  key: MyDayType.EXPERIENCE,
                  children: <ExperiencesTable mydays={mydays} refetch={refetch} setShowEditMydayModal={setShowEditMydayModal} />
                },
                {
                  label: 'Diary',
                  key: MyDayType.DIARY,
                  children: <DiaryTable mydays={mydays} refetch={refetch} setShowEditMydayModal={setShowEditMydayModal} />
                },
                {
                  label: 'Measurement',
                  key: MyDayType.MEASUREMENT,
                  children: <MeasurementsTable mydays={mydays} refetch={refetch} setShowEditMydayModal={setShowEditMydayModal} />
                },
                {
                  label: 'Medical / Bloodwork',
                  key: MyDayType.HEALTH_RELATED,
                  children: <HealthRelatedTable mydays={mydays} refetch={refetch} setShowEditMydayModal={setShowEditMydayModal} />
                },
                {
                  label: 'Note',
                  key: MyDayType.NOTE,
                  children: <NotesTable mydays={mydays} refetch={refetch} setShowEditMydayModal={setShowEditMydayModal} />
                }
              ]}
            ></Tabs>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <Button onClick={() => setShowCreateMydayModal(true)}>Create new</Button>
              <Button onClick={() => setShowAll(true)}>Export all</Button>
            </div>
            <CreateMyday
              showModal={showCreateMydayModal}
              onClose={() => setShowCreateMydayModal(false)}
              onOk={handleCreateMyday}
              user={user}
              type={activeKey}
            />
            <EditMyday
              showModal={showEditMydayModal}
              onClose={() => setShowEditMydayModal(false)}
              onOk={onOk}
              user={user}
              myday={showEditMydayModal}
            />

            <ShowAllMydays showModal={showAll} onCancel={() => setShowAll(false)} mydays={mydays} />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </AppContainer>
      <AppContainer>
        <div>
          <h2>Measurement graph</h2>
          {!isInitialLoading && (
            <Select
              style={{
                width: 300
              }}
              showSearch
              defaultValue={''}
              allowClear
              onChange={setShowGraphSelector}
              filterOption={(input, option) => {
                return (
                  option.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }}
            >
              <Option value="" title="none">
                Select a Health Metric to show
              </Option>
              <Option value="all" title="all">
                Show all
              </Option>
              {healthmetrictypes?.map((item) => (
                <Option key={item.id} value={item.id} title={item.description} on>
                  <span>{item.description}</span>
                </Option>
              ))}
            </Select>
          )}
          <HealthMetricGraphs selectedGraph={showGraphSelector} />
        </div>
      </AppContainer>
    </div>
  )
}

export default MyDayPage

import React from 'react'
import { Form, notification } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { SmileOutlined } from '@ant-design/icons'
import { SignupContainer, StyledLabel, StyledTextInput } from '../../styles'
import { handleSignup } from '../../fetchHooks/useFetchHooks'
import { ModalButtons } from '../MyDayPage/CreateMyday'

export const openNotification = (message, description) => {
  notification.open({
    message,
    description,
    placement: 'top',
    icon: (
      <SmileOutlined
        style={{
          color: '#108ee9'
        }}
      />
    ),
    onClick: () => {
      console.log('Notification Clicked!')
    }
  })
}
export const SignupModal = ({ show, onCancel, onOk }) => {
  const [form] = Form.useForm()
  if (!show) return <></>
  return (
    <Modal
      open={show}
      title="Signup"
      okText="Create"
      styles={{ padding: 0, borderRadius: '20px' }}
      cancelText="Cancel"
      className="modalStyle"
      onCancel={onCancel}
      footer={null}
      onOk={() => console.log('Validate Failed:')}
    >
      <Form form={form} layout="vertical" name="form_in_modal_create_agent" initialValues={{}}>
        <SignupContainer>
          {/* <div className="create-user-header">
            <h2>Create new agent </h2>
          </div> */}
          <div className="create-user-firstname">
            <Form.Item label={<StyledLabel>First name</StyledLabel>} name="firstname">
              <StyledTextInput type="textarea" />
            </Form.Item>
          </div>
          <div className="create-user-lastname">
            <Form.Item label={<StyledLabel>Last name</StyledLabel>} name="lastname">
              <StyledTextInput type="textarea" />
            </Form.Item>
          </div>
          <div className="create-user-hour-rate">
            <Form.Item label={<StyledLabel>Hour rate</StyledLabel>} name="hourRate">
              <StyledTextInput type="number" />
            </Form.Item>
          </div>
          <div className="create-user-email">
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input a valid email address'
                }
              ]}
              label={<StyledLabel>Email address</StyledLabel>}
              name="email"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-user-password">
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'password',
                  message: 'Please input a password'
                }
              ]}
              label={<StyledLabel>Password</StyledLabel>}
              name="password"
            >
              <StyledTextInput type="password" />
            </Form.Item>
          </div>

          <ModalButtons
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  const vals = {
                    ...values,
                    username: values.email,
                    role: 'USER'
                  }
                  handleSignup(vals, () => {
                    openNotification('Success', `Added user with username: ${vals.username}`)
                    onOk()
                  })
                  console.log('Signup', vals)
                  form.resetFields()
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
            onCancel={onCancel}
            okText="Signup"
          />
        </SignupContainer>
      </Form>
    </Modal>
  )
}

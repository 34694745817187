import React from 'react'
import ReactDOM from 'react-dom/client'
import './App.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import allReducers from './redux/reducers'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { AppWrapper, LoadingModal } from './styles'
// import { CookiesProvider } from 'react-cookie'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  // <CookiesProvider>
  <Provider store={store}>
    <AppWrapper>
      <ErrorBoundary
        fallback={
          <LoadingModal>
            <p style={{ textAlign: 'center' }}>There was an error</p>
          </LoadingModal>
        }
      >
        <App />
      </ErrorBoundary>
    </AppWrapper>
  </Provider>
  // </CookiesProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useQueryClient } from '@tanstack/react-query'
import { deleteMeasurement, useMyday } from '../../fetchHooks/useFetchHooks'
import { Button, Popconfirm, Typography } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

export const MeasurementsList = ({ mydayId = false }) => {
  const queryClient = useQueryClient()
  const { data: myday, isInitialLoading, error, refetch } = useMyday(mydayId)
  if (!myday) return <></>
  const { measurements } = myday
  if (!measurements?.length) return <></>
  return (
    <div>
      <Typography>Existing measurements:</Typography>

      {measurements.map((m) => (
        <div>
          <Popconfirm
            title="Are you sure to delete this measurement?"
            onConfirm={() => {
              deleteMeasurement(m.id, refetch)
              queryClient.invalidateQueries(['useFilesOnly'])
            }}
            onCancel={() => console.log('Canceled')}
            okText="Yes"
            cancelText="No"
          >
            <Button
              // size="small"
              icon={<DeleteOutlined />}
              // ghost
              className="delete-button"
            />
          </Popconfirm>
          {m.healthMetricType?.description}: {m.value} {m.healthMetricType?.unit}
        </div>
      ))}
    </div>
  )
}

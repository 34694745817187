import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { deleteMyday } from '../../fetchHooks/useFetchHooks'
import { LinkButton } from '../../styles'
import { useState } from 'react'
import { formatDateOnly } from '../../utils/utilFunctions'
import { MyDayType } from './MyDayPage'

export const DiaryTable = ({ mydays, setShowEditMydayModal, refetch }) => {
  const [hideWithNoMeasurements, setHideWithNoMeasurements] = useState(false)
  const [hideShortEntries, setHideShortEntries] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [excludeSearchString, setExcludeSearchString] = useState('')
  const navigate = useNavigate()
  const handleShowMydayDetails = ({ id }) => navigate(`/mydaydetails/${id}`)
  const columns = !mydays
    ? []
    : [
        {
          title: 'Date',
          //   width: '100%',
          dataIndex: 'date',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.date.localeCompare(b.date),
          key: 'date',
          render: (date, item) => <LinkButton onClick={() => handleShowMydayDetails(item)}>{formatDateOnly(new Date(date))}</LinkButton>,
          responsive: ['xs', 'sm', 'lg']
        },
        {
          title: 'Measurements',
          dataIndex: 'measurements',
          // sorter: (a, b) => a.rateMental.localeCompare(b.rateMental),
          key: 'measurements',
          render: (measurements, item) => {
            let mText = ''
            if (measurements?.length)
              measurements?.forEach((item, index) => (mText += `${index !== 0 ? ', ' : ''}${item.healthMetricType?.description}`))
            else mText = '-'
            return (
              <Tooltip title={mText}>
                <LinkButton style={{ maxWidth: '10rem' }} onClick={() => handleShowMydayDetails(item)}>
                  {mText}
                </LinkButton>
              </Tooltip>
            )
          },
          responsive: ['sm'],
          width: 15
        },
        {
          title: 'Description',
          dataIndex: 'description',
          sorter: (a, b) => a.description.localeCompare(b.description),
          key: 'description',
          render: (description, item) => <LinkButton onClick={() => handleShowMydayDetails(item)}>{description || '-'}</LinkButton>,
          responsive: ['xs', 'sm', 'lg']
        },
        {
          title: 'Files',
          dataIndex: 'file_count',
          sorter: (a, b) => a.file_count.localeCompare(b.file_count),
          key: 'file_count',
          render: (files, item) => <LinkButton onClick={() => handleShowMydayDetails(item)}>{files || '-'}</LinkButton>,
          responsive: ['sm', 'lg']
        },

        {
          title: 'Action',
          key: 'action',
          render: (text, item) => (
            <Space.Compact size="middle">
              <Tooltip title="Open Myday details">
                <Button
                  shape="round"
                  size="small"
                  icon={<EyeOutlined />}
                  onClick={() => handleShowMydayDetails(item)}
                  className="edit-button"
                />
              </Tooltip>
              <Tooltip title="Edit Myday">
                <Button
                  shape="round"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => setShowEditMydayModal(item)}
                  className="edit-button"
                />
              </Tooltip>
              <Tooltip title="Delete Myday">
                <Popconfirm
                  title="Are you sure to delete this item?"
                  onConfirm={() => deleteMyday(item.id, refetch)}
                  onCancel={() => console.log('Canceled')}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="edit-button" shape="round" size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Space.Compact>
          ),
          responsive: ['xs', 'sm', 'lg']
        }
      ]

  const filteredMyDays = mydays?.filter(function (item) {
    if (hideWithNoMeasurements && !item.measurements?.length) return false
    if (hideShortEntries && item.note?.length < 200) return false
    if (item?.type !== MyDayType.DIARY) return false
    if (!!searchString && item.description) {
      const lowerCaseDescription = item.description.toLowerCase()
      const lowerCaseNote = item.note.toLowerCase()
      const lowerCaseSearchString = searchString.toLowerCase()
      return lowerCaseDescription.includes(lowerCaseSearchString) || lowerCaseNote.includes(lowerCaseSearchString)
    }
    if (!!excludeSearchString && item.description) {
      const lowerCaseDescription = item.description.toLowerCase()
      const lowerCaseNote = item.note.toLowerCase()
      const lowerCaseSearchString = excludeSearchString.toLowerCase()
      return !(lowerCaseDescription.includes(lowerCaseSearchString) || lowerCaseNote.includes(lowerCaseSearchString))
    }
    return true
  })

  return (
    <>
      Showing {filteredMyDays.length} of {mydays.length} diary entries.
      <div className="myday-table-text-container">
        <div>
          Hide:
          <label>
            <input type="checkbox" checked={hideWithNoMeasurements} onChange={() => setHideWithNoMeasurements((prev) => !prev)} />
            Entries without measurements
          </label>
          <label>
            <input type="checkbox" checked={hideShortEntries} onChange={() => setHideShortEntries((prev) => !prev)} />
            Short entries
          </label>
        </div>
        <div className="excludes-container">
          Exclude: <input type="text" value={excludeSearchString} onChange={(e) => setExcludeSearchString(e.target.value)} />
          {!!excludeSearchString?.length && (
            <Button size="small" onClick={() => setExcludeSearchString('')}>
              Clear
            </Button>
          )}
        </div>
        <div className="includes-container">
          Include: <input type="text" value={searchString} onChange={(e) => setSearchString(e.target.value)} />
          {!!searchString?.length && (
            <Button size="small" onClick={() => setSearchString('')}>
              Clear
            </Button>
          )}
        </div>
      </div>
      <Table
        dataSource={filteredMyDays}
        scroll={{ x: true }}
        fixed
        bordered
        size="small"
        columns={columns}
        rowKey="id"
        style={{ marginBottom: 16 }}
      />
    </>
  )
}

import { Highlight } from '@mantine/core'
import { RichTextEditor, Link, useRichTextEditorContext } from '@mantine/tiptap'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { FloatingMenu, useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import { useState } from 'react'
import './MydayDetails.scss'
import { IconPhoto } from '@tabler/icons-react'
import { MyImagePickerItem } from '../../components/MyImage/MyImage'
import { Modal } from 'antd'
import { envApiUrl, useImages } from '../../fetchHooks/useFetchHooks'
// const text =
//   '<h2 style="text-align: center;">Welcome to Mantine rich text editor</h2><p><code>RichTextEditor</code> component focuses on usability and is designed to be as simple as possible to bring a familiar editing experience to regular users. <code>RichTextEditor</code> is based on <a href="https://tiptap.dev/" rel="noopener noreferrer" target="_blank">Tiptap.dev</a> and supports all of its features:</p><ul><li>General text formatting: <strong>bold</strong>, <em>italic</em>, <u>underline</u>, <s>strike-through</s> </li><li>Headings (h1-h6)</li><li>Sub and super scripts (<sup>&lt;sup /&gt;</sup> and <sub>&lt;sub /&gt;</sub> tags)</li><li>Ordered and bullet lists</li><li>Text align&nbsp;</li><li>And all <a href="https://tiptap.dev/extensions" target="_blank" rel="noopener noreferrer">other extensions</a></li></ul>'

const MyRichTextEditor = ({ text, onChange, myday }) => {
  const [content, setContent] = useState(text)
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Image,
      Dropcursor,
      StarterKit,
      Underline,
      Link,
      Superscript,
      Subscript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] })
    ],
    content,
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML())
      onChange?.(editor.getHTML())
    }
  })

  return (
    <div className="tiptap-editor">
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <InsertImageControl />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        {editor && (
          <FloatingMenu editor={editor}>
            <RichTextEditor.ControlsGroup>
              <ShowImagePickerControl myday={myday} />
            </RichTextEditor.ControlsGroup>
          </FloatingMenu>
        )}

        <RichTextEditor.Content />
      </RichTextEditor>
      {/* <Button onClick={() => console.log('Save: ', content)}>Save</Button> */}
    </div>
  )
}

// function InsertImageControl() {
//   const { editor } = useRichTextEditorContext()

//   const addImage = (e) => {
//     e.preventDefault()
//     const url = window.prompt('URL')

//     if (url) {
//       editor
//         .chain()
//         .focus()
//         .setImage({ src: url })
//         .run()
//     }
//   }

//   if (!editor) {
//     return null
//   }
//   return (
//     <RichTextEditor.Control onClick={addImage} aria-label="Insert star emoji" title="Insert image">
//       <IconPictureInPicture stroke={1.5} size={16} />
//     </RichTextEditor.Control>
//   )
// }

function ShowImagePickerControl({ myday }) {
  const { editor } = useRichTextEditorContext()
  const [showModal, setShowModal] = useState(false)
  const { data: images, isLoading } = useImages(myday?.id)
  if (!images?.length || isLoading || !showModal || !myday?.id) return <></>

  const openImagePickerModal = (e) => {
    setShowModal(true)
  }
  const addImage = (item) => {
    const { src: url } = item
    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
    setShowModal(false)
  }

  const onClose = () => {
    setShowModal(false)
  }

  if (!editor) {
    return null
  }
  if (showModal)
    return (
      <Modal
        open={showModal}
        onCancel={onClose}
        footer={null}
        centered
        width="1024px"
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        wrapClassName="centered-modal"
        styles={{ padding: 0, zIndex: 1000 }}
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}
      >
        <div>
          <h1>Pick image to insert:</h1>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(266px, 1fr))', gridColumnGap: 32, gridRowGap: 32 }}>
            {images?.map((image) => {
              const { id, name } = image
              const src = `${envApiUrl}/files/image/${id}`
              const alt = name
              return (
                <MyImagePickerItem
                  // height="250px"
                  onClick={addImage}
                  onClose={onClose}
                  alt={alt}
                  src={src}
                  key={id}
                />
              )
            })}
          </div>
        </div>
      </Modal>
    )

  return (
    <RichTextEditor.Control onClick={openImagePickerModal} aria-label="Insert star emoji" title="Insert image">
      <IconPhoto stroke={1.5} size={16} />
    </RichTextEditor.Control>
  )
}

function InsertImageControl() {
  const { editor } = useRichTextEditorContext()

  const addImage = (e) => {
    e.preventDefault()
    const url = window.prompt('URL')

    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
  }

  if (!editor) {
    return null
  }

  return (
    <RichTextEditor.Control onClick={addImage} aria-label="Insert star emoji" title="Insert image">
      <IconPhoto stroke={1.5} size={16} />
    </RichTextEditor.Control>
  )
}
// function InsertImageControl() {
//   const { editor } = useRichTextEditorContext()
//   return (
//     <RichTextEditor.Control onClick={() => editor?.commands.insertContent('⭐')} aria-label="Insert star emoji" title="Insert star emoji">
//       <IconPictureInPicture stroke={1.5} size={16} />
//     </RichTextEditor.Control>
//   )
// }

export default MyRichTextEditor

import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'

import { handleCreateMyPassword } from '../../fetchHooks/useFetchHooks'
import { ModalButtons } from '../MyDayPage/CreateMyday'
import { openNotification } from '../LoginPage/SignupModal'
import { ModalTitle } from '../MyDayPage/EditMyday'
import './MyPasswords.scss'

export const CreatePasswordModal = ({ show, onCancel, onOk }) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form && form.resetFields()
  }, [form])

  if (!show) return <></>

  return (
    <Modal
      open={show}
      styles={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="create-password-modal"
      onCancel={onCancel}
      footer={null}
      onOk={() => console.log('Validate Failed:')}
      forceRender
    >
      <ModalTitle title="Create credential" />
      <Form
        form={form}
        name="create-password"
        // onFinish={onFinish}
        className="create-password-form"
        initialValues={{ website: '', password: '' }}
        scrollToFirstError
      >
        <Form.Item
          name="website"
          label={<p>Website/Service</p>}
          rules={[
            {
              required: true,
              message: 'Please input website!'
            }
          ]}
        >
          <Input autoComplete="off" list="autocompleteOff" />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: 'Please input username!'
            }
          ]}
        >
          <Input autoComplete="off" list="autocompleteOff" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input the password!'
            }
          ]}
          hasFeedback
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm the password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              }
            })
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <Form.Item name="comment" label="Comment">
          <Input.TextArea showCount maxLength={1000} />
        </Form.Item>
        <ModalButtons
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                const vals = {
                  ...values
                }
                handleCreateMyPassword(vals, () => {
                  openNotification('Success', `Added credential for website: ${vals.website}`)
                  onOk?.()
                })
                console.log('Create credential', vals)
                form.resetFields()
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
          onCancel={onCancel}
          okText="Create"
        />
        {/* 
      <Form.Item label="Captcha" extra="We must make sure that your are a human.">
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="captcha"
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Please input the captcha you got!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button>Get captcha</Button>
          </Col>
        </Row>
      </Form.Item> */}
        {/* 
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          I have read the <a href="">agreement</a>
        </Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item> */}
      </Form>
    </Modal>
  )
}

import { ConfigProvider, DatePicker, Form, Radio } from 'antd'
import { ModalButtons } from './CreateMyday'
import TextArea from 'antd/es/input/TextArea'
import MyRichTextEditor from './MyRichTextEditor'
import { AddHealthMetricForm } from './AddHealthMetricForm'
import locale from 'antd/es/locale/en_GB'
import { MeasurementsList } from './MeasurementsList'
import { MyDayType } from './MyDayPage'
import { useEffect } from 'react'

export const MydayForm = ({ form, onFinish, onCancel, isEdit, myday, type = 'measurement', children }) => {
  useEffect(() => {
    form.setFieldsValue({
      type: type
    })
  }, [type])

  return (
    <Form form={form} layout="vertical" preserve={false} size={'default'} onFinish={onFinish}>
      <div>
        <Form.Item name="type" label="Select Type">
          <Radio.Group>
            <Radio.Button value={MyDayType.EXPERIENCE}>Experience</Radio.Button>
            <Radio.Button value={MyDayType.DIARY}>Diary</Radio.Button>
            <Radio.Button value={MyDayType.MEASUREMENT}>Measurement</Radio.Button>
            <Radio.Button value={MyDayType.HEALTH_RELATED}>Medical / Bloodwork</Radio.Button>
            <Radio.Button value={MyDayType.NOTE}>Note</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <div className="create-myday-date">
          <ConfigProvider locale={locale}>
            <Form.Item
              name="date"
              label={<p>Date and Time</p>}
              rules={[
                {
                  required: true,
                  message: 'Please choose meeting date and time'
                }
              ]}
              style={{ marginBottom: 0 }}
            >
              <DatePicker format="YYYY-MM-DD" placeholder="Select date" picker="day" />
            </Form.Item>
          </ConfigProvider>
        </div>
        <br />
        <MeasurementsList mydayId={myday?.id} />
        <AddHealthMetricForm form={form} />
        {children}
        <div className="create-appointment-description">
          <Form.Item
            name="description"
            label={'Description'}
            rules={[
              {
                required: true,
                message: 'Please write a description'
              }
            ]}
          >
            <TextArea placeholder="Please add text here..." rows={1} />
          </Form.Item>
        </div>
        <div className="create-appointment-note">
          <Form.Item
            name="note"
            label={'Note'}
            rules={[
              {
                required: true,
                message: 'Please write a note'
              }
            ]}
          >
            <MyRichTextEditor myday={myday} text={myday?.note} />
          </Form.Item>
        </div>
        <ModalButtons onOk={form.submit} okText={isEdit ? 'Save' : 'Create'} onCancel={onCancel} />
      </div>
    </Form>
  )
}

import React, { useEffect, useState } from 'react'
import './BlurScreenWhenInactive.css'

const BlurScreenWhenInactive = ({}) => {
  // if (!blur) return <></>
  const [inactive, setInactive] = useState(false)
  const [resetButtonClicked, setResetButtonClicked] = useState(false)

  const inactivityTime = 30 * 1000 // 30 seconds in milliseconds
  let inactivityTimeout

  // Function to reset the inactivity timer
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimeout)
    startInactivityTimer()
    setInactive(false) // Reset the inactive state to false
    setResetButtonClicked(false) // Reset the reset button state
  }

  // Function to start the inactivity timer
  const startInactivityTimer = () => {
    inactivityTimeout = setTimeout(() => {
      setInactive(true)
    }, inactivityTime)
  }

  useEffect(() => {
    // Add event listeners to track user activity
    const handleMouseMove = () => !inactive && resetInactivityTimer()
    const handleKeyDown = () => !inactive && resetInactivityTimer()
    const handleScroll = () => !inactive && resetInactivityTimer()

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('scroll', handleScroll)

    // Start the inactivity timer when the component mounts
    startInactivityTimer()

    // Clean up event listeners and the inactivity timeout when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(inactivityTimeout)
    }
  }, [inactive])

  if (!inactive) return <></>

  return (
    <button
      className="blur-screen"
      onClick={() => {
        setResetButtonClicked(true)
        resetInactivityTimer()
        setInactive(false) // Reset the inactive state to false
      }}
      disabled={resetButtonClicked}
    >
      Click anywhere to show text
    </button>
  )
}

export default BlurScreenWhenInactive

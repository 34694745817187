import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns'
import { Divider } from 'antd'
import React from 'react'
import Modal from 'antd/es/modal/Modal'
import { formatDateOnly } from '../../utils/utilFunctions'
import { LinkButton } from '../../styles'
import { useNavigate } from 'react-router-dom'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

function convertNmolLToNgDL(nmolLValue, healthMetricName, withUnit = false) {
  let conversionRate
  let newUnit = 'ng/dL'
  if (healthMetricName === 'testosterone') {
    conversionRate = 28.818
  }
  if (healthMetricName === 'glucose') {
    conversionRate = 18.016
  }
  if (healthMetricName === 'estradiol') {
    conversionRate = 272.38
    newUnit = 'pg/mL'
  }
  if (withUnit) return conversionRate ? (nmolLValue * conversionRate).toFixed(1) + ' ' + newUnit : 'N/A'
  return conversionRate ? (nmolLValue * conversionRate).toFixed(1) : 'N/A'
}

export const MydayStatsGraph = ({ healthMetric }) => {
  const [showModal, setShowModal] = React.useState(false)
  const navigate = useNavigate()
  if (!healthMetric?.id) return <></>
  const handleShowMydayDetails = (mydayid) => {
    console.log('navitation to: ', `/mydaydetails/${mydayid}`)
    navigate(`/mydaydetails/${mydayid}`)
  }

  const isNmolL = healthMetric?.unit === 'nmol/L'
  const measurements = healthMetric?.measurements.sort((a, b) => new Date(a.myday?.date) - new Date(b.myday?.date))
  const maxNmolL = Math.max(...measurements.map((item) => item.value), healthMetric.ref_upper)
  const maxNgDL = convertNmolLToNgDL(maxNmolL, healthMetric?.name)
  const minNmolL = Math.min(...measurements.map((item) => item.value), healthMetric.ref_lower)
  const minNgDL = convertNmolLToNgDL(minNmolL, healthMetric?.name)

  const options = {
    responsive: true,
    scales: {
      x: {
        parsing: false,
        type: 'time',
        time: {
          unit: 'day'
        },
        ticks: {
          major: {
            enabled: true
          }
          // color: '#FF0000'
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        min: minNmolL * 0.95, // Set the minimum value for nmol/L
        max: maxNmolL * 1.05, // Set the maximum value
        title: {
          display: true,
          text: healthMetric?.unit
        }
      },
      y1: {
        type: 'linear',
        display: !!isNmolL && !isNaN(convertNmolLToNgDL(measurements?.[0]?.value, healthMetric?.name)),
        position: 'right',
        min: minNgDL * 0.95, // Set the minimum value for ng/dL
        max: maxNgDL * 1.05, // Set the maximum value
        grid: {
          drawOnChartArea: false
        },
        title: {
          display: true,
          text: 'ng/dL'
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      }
    }
  }

  const data = (compact = false) => {
    const tension = 0.1
    const retVal = {
      labels: measurements?.map(({ myday }) => myday.date),
      datasets: [
        {
          label: healthMetric?.unit || 'Value',
          data: measurements?.map((item) => item.value),
          borderColor: 'blue',
          backgroundColor: 'blue',
          tension,
          borderWidth: compact ? 1 : 2
        }
      ]
    }

    if (isNmolL && !isNaN(convertNmolLToNgDL(measurements?.[0]?.value, healthMetric?.name)))
      retVal?.datasets?.push({
        label: 'ng/dL',
        data: measurements?.map((item) => convertNmolLToNgDL(item.value, healthMetric?.name)),

        borderColor: 'green', // Choose a color
        backgroundColor: 'green', // Choose a color
        tension,
        borderWidth: 0, //compact ? 1 : 2,
        yAxisID: 'y1' // This dataset is tied to the new Y-axis
      })

    if (!!healthMetric.ref_lower)
      retVal?.datasets?.push({
        label: 'Ref Low',
        data: measurements?.map((item) => healthMetric.ref_lower),
        borderColor: 'pink',
        backgroundColor: 'pink',
        tension
      })
    if (!!healthMetric.ref_upper)
      retVal?.datasets?.push({
        label: 'Ref High',
        data: measurements?.map((item) => healthMetric.ref_upper),
        borderColor: 'pink',
        backgroundColor: 'pink',
        tension
      })
    return retVal
  }

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>{healthMetric?.description}:</h3>
      <Line options={options} data={data(true)} onClick={() => setShowModal((prev) => !prev)} />
      <p style={{ whiteSpace: 'pre-wrap' }}>{healthMetric?.comment}</p>
      <Divider />
      <Modal
        open={showModal}
        id="video-player-modal"
        onCancel={() => setShowModal(false)}
        styles={{ padding: 0, borderRadius: '20px' }}
        width="1080px"
        footer={null}
        centered
        destroyOnClose
      >
        {showModal && (
          <div>
            <h3 style={{ textAlign: 'center' }}>All {healthMetric?.description} measurements:</h3>
            {/* <Line options={options} data={data()} onClick={() => setShowModal((prev) => !prev)} /> */}
            <TableWithTwoRows measurements={measurements} onClick={handleShowMydayDetails} healthMetric={healthMetric} isNmolL={isNmolL} />
          </div>
        )}
      </Modal>
    </div>
  )
}

const TableWithTwoRows = ({ measurements, onClick, healthMetric, isNmolL = false }) => {
  if (!measurements) return <></>
  const tableRows = measurements.map((item, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <LinkButton style={{ maxWidth: '10rem' }} onClick={() => onClick?.(item.myday?.id)}>
          {formatDateOnly(new Date(item.myday?.date))}
        </LinkButton>
      </td>
      <td>
        <LinkButton onClick={() => onClick?.(item.myday?.id)}>
          {item.value} {healthMetric.unit}{' '}
          {isNmolL &&
            !isNaN(convertNmolLToNgDL(measurements?.[0]?.value, healthMetric?.name)) &&
            `(${convertNmolLToNgDL(item.value, healthMetric?.name, true)})`}
        </LinkButton>
      </td>
    </tr>
  ))

  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Date</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  )
}

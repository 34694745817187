import { DeleteOutlined } from '@ant-design/icons'
import { Image, Button, Popconfirm, Card } from 'antd'
import { deleteFile, envApiUrl, useImages } from '../../fetchHooks/useFetchHooks'
import React, { useEffect, useState } from 'react'
import 'video-react/dist/video-react.css'
import { ImageModal, MyImage } from '../../components/MyImage/MyImage'
import { CopyToClipboardButton, FileDownloadLink } from '../../utils/utilFunctions'
import FileUpdateDebounced from './FileUpdateDebounced'
import { useEscapeKey } from './Files'

export const Images = ({ myday, compact = false, isEdit = false, isExport = false, onClick, onClose }) => {
  const { data: images, refetch, isLoading } = useImages(myday.id)
  const [showModal, setShowModal] = useState(false)
  const [clickedImage, setClickedImage] = useState({ src: undefined, alt: undefined })

  const goToNextImage = () => {
    const newArrayNumber = getNextItem(images, clickedImage.index)
    const { description, id } = images[newArrayNumber]
    setClickedImage({ src: `${envApiUrl}/files/image/${id}`, description, index: newArrayNumber })
  }

  const goToPrevImage = () => {
    const newArrayNumber = getPrevItem(images, clickedImage.index)
    const { description, id } = images[newArrayNumber]
    setClickedImage({ src: `${envApiUrl}/files/image/${id}`, description, index: newArrayNumber })
  }

  useRightArrowKey(goToNextImage)
  useLeftArrowKey(goToPrevImage)
  useEscapeKey(() => setShowModal(false))

  if (!images?.length || isLoading) return <></>
  if (isEdit)
    return (
      <div>
        <h3>Images:</h3>
        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: '3rem' }}> */}
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))' }}>
          {images?.map((image) => {
            const { id, name, description } = image

            return (
              <div style={{ margin: 4 }} key={id}>
                <Card
                  style={{
                    maxWidth: compact ? '50%' : 300,
                    height: compact ? 300 : 400,
                    // margin: 16,
                    overflow: 'hidden'
                  }}
                  cover={<Image src={`${envApiUrl}/files/image/${id}`} />}
                  actions={[
                    <Popconfirm
                      title="Are you sure to delete this item?"
                      onConfirm={() => deleteFile(id, refetch)}
                      onCancel={() => console.log('Canceled')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="danger"
                        shape="round"
                        size={compact ? 'small' : 'default'}
                        icon={<DeleteOutlined />}
                        ghost
                        className="delete-button"
                      />
                    </Popconfirm>,

                    <FileDownloadLink fileId={id} fileName={name} />,
                    <CopyToClipboardButton text={`${envApiUrl}/files/image/${id}`} />
                    // <FileDownloadLink fileId={id} fileName={name} />
                  ]}
                >
                  <FileUpdateDebounced fileId={id} label="Description" propertyName="description" value={description} />
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    )
  if (isExport)
    return (
      <div>
        {images?.map((image) => {
          const { id, name } = image
          return <MyImage src={`${envApiUrl}/files/image/${id}`} />
        })}
      </div>
    )

  return (
    <div>
      <h3>Images:</h3>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(266px, 1fr))', gridColumnGap: 32, gridRowGap: 32 }}>
        {images?.map((image, index) => {
          const { id, name, description } = image
          const src = `${envApiUrl}/files/image/${id}`
          return (
            <MyImage
              compact={compact}
              // height="250px"
              name={name}
              src={src}
              key={id}
              description={image.description}
              index={index}
              onClick={() => {
                setShowModal(true)
                setClickedImage({ src, description, index })
              }}
            />
          )
        })}
        {showModal && <ImageModal clickedImage={clickedImage} onClose={() => setShowModal(false)} onClick={goToNextImage} />}
      </div>
    </div>
  )
}

const getNextItem = (arr, n) => {
  if (arr.length > n + 1) return n + 1
  return 0
}

const getPrevItem = (arr, n) => {
  let retVal = arr.length - 1
  if (n - 1 >= 0) return n - 1
  return retVal
}

export const useRightArrowKey = (callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        callback?.()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback]) // Empty dependency array ensures the effect is only run once
}

export const useLeftArrowKey = (callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        callback?.()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback]) // Empty dependency array ensures the effect is only run once
}

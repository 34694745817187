import React, { useRef } from 'react'
import { Divider } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import 'moment/locale/en-gb'
import MydayDetails from './MydayDetails'
import ReactToPrint from 'react-to-print'

export const ModalTitle = ({ title }) => (
  <>
    <h1
      style={{
        textAlign: 'center',
        paddingBottom: '2rem',
        paddingTop: '2rem',
        background: 'rgba(147, 188, 255, 0.3)',
        borderRadius: 16
      }}
    >
      {title}
    </h1>
    <Divider />
  </>
)

export const ShowAllMydays = ({ mydays, showModal = false, onCancel }) => {
  const componentRef = useRef()
  return (
    <Modal
      open={showModal}
      id="edit-appointment"
      onCancel={onCancel}
      styles={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="modalStyle"
      width="720px"
      footer={null}
      centered
      destroyOnClose
    >
      <ReactToPrint trigger={() => <button>Print this out!</button>} content={() => componentRef.current} />
      <div ref={componentRef}>
        {mydays?.map((myday) => (
          <MydayDetails key={myday.id} mydayId={myday.id} isExport />
        ))}{' '}
      </div>
    </Modal>
  )
}

import { useSearchParams } from 'react-router-dom'
import { useYearStats } from '../../fetchHooks/useFetchHooks'
import { formatCurrency } from '../Month/Day'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns'

import '../../App.scss'
import Navbar from '../Navbar/Navbar'
import { AppContainer } from '../../styles'

const YearStatsGraph = ({ yearData, isAccumulated = false, isPerMonth = false }) => {
  if (!yearData) return <></>

  const calculateData = () => {
    let accumulated = 0
    const retValue = [
      {
        name: 'Jan',
        earned: yearData?.jan?.earnedTotalMonth,
        workingdays: yearData?.jan?.earnedTotalMonth
      },
      {
        name: 'Feb',
        earned: yearData?.feb?.earnedTotalMonth,
        workingdays: yearData?.feb?.earnedTotalMonth
      },
      {
        name: 'Mar',
        earned: yearData?.mar?.earnedTotalMonth,
        workingdays: yearData?.mar?.earnedTotalMonth
      },
      {
        name: 'Apr',
        earned: yearData?.apr?.earnedTotalMonth,
        workingdays: yearData?.apr?.earnedTotalMonth
      },
      {
        name: 'May',
        earned: yearData?.may?.earnedTotalMonth,
        workingdays: yearData?.may?.earnedTotalMonth
      },
      {
        name: 'Jun',
        earned: yearData?.jun?.earnedTotalMonth,
        workingdays: yearData?.jun?.earnedTotalMonth
      },
      {
        name: 'Jul',
        earned: yearData?.jul?.earnedTotalMonth,
        workingdays: yearData?.jul?.earnedTotalMonth
      },
      {
        name: 'Aug',
        earned: yearData?.aug?.earnedTotalMonth,
        workingdays: yearData?.aug?.earnedTotalMonth
      },
      {
        name: 'Sep',
        earned: yearData?.sep?.earnedTotalMonth,
        workingdays: yearData?.sep?.earnedTotalMonth
      },
      {
        name: 'Oct',
        earned: yearData?.oct?.earnedTotalMonth,
        workingdays: yearData?.oct?.earnedTotalMonth
      },
      {
        name: 'Nov',
        earned: yearData?.nov?.earnedTotalMonth,
        workingdays: yearData?.nov?.earnedTotalMonth
      },
      {
        name: 'Dec',
        earned: yearData?.dec?.earnedTotalMonth,
        workingdays: yearData?.dec?.earnedTotalMonth
      }
    ]
    return retValue.map((item) => {
      accumulated += item.earned
      return {
        ...item,
        earnedAccumulated: accumulated
      }
    })
  }

  const options = {
    responsive: true,
    scales: {
      x: {
        parsing: false,
        // type: 'line',
        // time: {
        //   unit: 'day'
        // },
        // display: true,
        // title: {
        //   display: true,
        //   text: 'Date'
        // },
        ticks: {
          major: {
            // enabled: true
          }
          // color: '#FF0000'
        }
      },
      y: {
        // beginAtZero: true
        // display: true,
        title: {
          display: true,
          text: 'Income'
        },
        ticks: {
          beginAtZero: true,
          max: 10,
          // stepSize: 100, // Set a fixed interval between ticks
          display: false // Hide Y-axis labels
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      }
      // title: {
      //   display: true,
      //   text: healthMetric?.description
      // }
    }
  }

  const data = (compact = false) => {
    const tension = 0.1
    const retVal = {
      labels: calculateData?.()?.map(({ name }) => name),
      datasets: []
    }

    if (isPerMonth)
      retVal?.datasets?.push({
        label: 'Per month',
        // yAxisID: 'y-axis-1', // Specify the ID of the Y-axis for this dataset
        data: calculateData?.()?.map(({ earned }) => earned),
        borderColor: 'blue',
        backgroundColor: 'blue',
        tension,
        borderWidth: compact ? 1 : 2
      })

    if (isAccumulated)
      retVal?.datasets?.push({
        label: 'Accumulated',
        // yAxisID: 'y-axis-2', // Specify the ID of the Y-axis for this dataset
        data: calculateData?.()?.map(({ earnedAccumulated }) => earnedAccumulated),
        borderColor: 'green',
        backgroundColor: 'green',
        tension,
        borderWidth: compact ? 1 : 2
      })

    return retVal
  }

  return <Line options={options} data={data()} style={{ maxHeight: '400px' }} />
}

const Year = () => {
  const [searchParams] = useSearchParams()
  const year = searchParams.get('year')
  const currentMonth = parseInt(searchParams.get('month'))
  const { data: yearData, isLoading } = useYearStats(year)
  if (isLoading) return <>Loading...</>
  const { flexitimeHoursTotal } = yearData

  if (!!year && !currentMonth) {
    console.log('Showing Year stats page')
    return (
      <div>
        <Navbar year={year} />
        <AppContainer>
          <h1>Year statistics for {year}:</h1>
          <p>Flexitime hours available: {flexitimeHoursTotal}</p>
          {/* <div style={{ height: '400px' }}> */}
          <YearStatsGraph yearData={yearData} isPerMonth />
          <YearStatsGraph yearData={yearData} isAccumulated />
          {/* </div> */}
        </AppContainer>
      </div>
    )
  }

  return (
    <div>
      <Navbar year={year} />
      <AppContainer>
        <p>
          Estimated earnings for {year}: {formatCurrency(yearData?.earnedTotal)}.
        </p>
        <p>Total workingdays: {yearData?.workingDaysTotal}</p>
      </AppContainer>
    </div>
  )
}

export default Year

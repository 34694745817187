import axios from 'axios'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { message } from 'antd'
import { waitForNSeconds } from '../utils/utilFunctions'
// eslint-disable-next-line no-undef
export const envApiUrl = process.env.REACT_APP_API_URL

export const useModuleAccessCheck = (service) => {
  const { data: user } = useUserInfo()
  return user?.services?.find?.((item) => item === service)
}

export const useFilesOnly = (mydayId) => {
  return useQuery(
    {
      queryKey: ['useFilesOnly', mydayId],

      queryFn: () =>
        axios
          .get(`${envApiUrl}/files/`, {
            headers: {
              'Content-Type': 'application/json',
              'content-type': 'multipart/form-data',
              mydayId
            },
            // responseType: 'arraybuffer',
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            // queryClient.invalidateQueries(['useYearStats'])
            return response.data
          })
    }
    // {
    //   enabled: !!mydayId
    // }
  )
}
export const useAllFiles = (mydayId) => {
  return useQuery(
    {
      queryKey: ['useAllFiles', mydayId],

      queryFn: () =>
        axios
          .get(`${envApiUrl}/files/all`, {
            headers: {
              'Content-Type': 'application/json',
              'content-type': 'multipart/form-data',
              mydayId
            },
            // responseType: 'arraybuffer',
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            // queryClient.invalidateQueries(['useYearStats'])
            return response.data
          })
    }

    // {
    //   enabled: !!mydayId
    // }
  )
}
export const useImages = (mydayId) => {
  return useQuery(
    {
      queryKey: ['useImages'],

      queryFn: () =>
        mydayId
          ? axios
              .get(`${envApiUrl}/files/images`, {
                headers: {
                  'Content-Type': 'application/json',
                  'content-type': 'multipart/form-data',
                  mydayId
                },
                // responseType: 'arraybuffer',
                withCredentials: true,
                xsrfCookieName: 'csrftoken_testtest',
                xsrfHeaderName: 'X-CSRFToken'
              })
              .then((response) => {
                // queryClient.invalidateQueries(['useYearStats'])
                return response.data
              })
          : { data: [] }
    }

    // {
    //   enabled: !!mydayId
    // }
  )
}
export const downloadFile = (id) => {
  axios
    .get(`${envApiUrl}/files/download`, {
      headers: {
        'Content-Type': 'application/json',
        id
      },
      // responseType: 'arraybuffer',
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      // queryClient.invalidateQueries(['useYearStats'])
      return response.data
    })
}

export const deleteFile = (id, refetch) =>
  id &&
  axios
    .delete(envApiUrl + '/files/', {
      headers: {
        'Content-Type': 'application/json',
        id
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteFile: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully deleted file')
    })
    .catch((err) => {
      console.error('deleteFile error: ', err.response?.data?.message, err)
      message.error(err.response?.data?.message)
    })

export const handleUploadFile = (file, myday, onOk, setUploadProgress) => {
  console.log('handleUploadFile file', file)
  myday.id &&
    axios
      .post(envApiUrl + '/files', file, {
        headers: {
          'content-type': 'multipart/form-data',
          mydayId: myday.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken',
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setUploadProgress?.(progress)
        }
      })
      .then((response) => {
        console.log('Create File: response: ', response)
        return response.data
      })
      .finally(() => {
        onOk?.()
        message.success('Successfully uploaded file')
      })
      .catch((err) => {
        console.error('handleUploadFile error: ', err.response?.data?.message, err)
        message.error(err.response?.data?.message)
      })
}

export const useIsLoggedIn = () => {
  return useQuery({
    queryFn: () =>
      // user &&
      axios
        .get(envApiUrl + '/users/isLoggedIn', {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          credentials: 'include', // Don't forget to specify this if you need cookies
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .catch((error) => console.log('useEffect error', error)),
    queryKey: ['isLoggedIn']
  })
}

export const useUserInfo = () => {
  return useQuery(
    {
      queryKey: ['userInfo'],
      queryFn: () =>
        // user &&
        axios
          .get(envApiUrl + '/users/', {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => response.data)
          .catch(function (error) {
            if (error.response) {
              console.log('AAA: ', error.response.data) // Message
              console.log(error.response.status) // Error code number
              console.log(error.response.headers)
            }
            if (error.response?.status === 403) {
              console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
              window.location.href = '/signout'
            }
          })
    }
    // {
    //   // enabled: !!isLoggedIn && !isInitialLoading,
    //
    // }
  )
}

export const useYearStats = (year) => {
  return useQuery(
    {
      queryKey: ['useYearStats'],

      queryFn: () =>
        year &&
        axios
          .get(`${envApiUrl}/years/stats`, {
            headers: {
              'Content-Type': 'application/json',
              year
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => response.data)
    }

    // {
    //   enabled: true
    // }
  )
}

export const useDays = (year) => {
  return useQuery(
    {
      queryKey: ['useDays'],

      queryFn: () =>
        axios
          .get(`${envApiUrl}/days/`, {
            headers: {
              'Content-Type': 'application/json',
              year
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => response.data)
    }

    // {
    //   enabled: false
    // }
  )
}

export const useMonth = (monthId) => {
  return useQuery(
    {
      queryKey: ['useMonth', monthId],

      queryFn: () =>
        axios
          .get(`${envApiUrl}/months/findMonth`, {
            headers: {
              'Content-Type': 'application/json',
              id: monthId
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => response.data)
    }

    // {
    //   enabled: !!monthId
    // }
  )
}
export const useUniqueYears = () => {
  return useQuery(
    {
      queryKey: ['useUniqueYears'],

      queryFn: () =>
        axios
          .get(`${envApiUrl}/years/getyears`, {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => response.data)
    }

    // {
    //   enabled: true
    // }
  )
}

export const useRegistrations = (year, month) => {
  return useQuery(
    {
      queryKey: ['useRegistrations', year, month],

      queryFn: () =>
        year &&
        axios
          .get(`${envApiUrl}/registrations/getregistrations`, {
            headers: {
              'Content-Type': 'application/json',
              year,
              month: !isNaN(month) ? month : null
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            // queryClient.invalidateQueries(['useYearStats'])
            return response.data
          })
    }
    // {
    //   enabled: !!year
    // }
  )
}
export const useMydays = (year, month) => {
  return useQuery({
    queryKey: ['useMydays', year, month],

    queryFn: () =>
      axios
        .get(`${envApiUrl}/mydays/`, {
          headers: {
            'Content-Type': 'application/json',
            year,
            month
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => {
          // queryClient.invalidateQueries(['useYearStats'])
          return response.data
        })
        .catch(function (error) {
          if (error.response) {
            console.log('AAA: ', error.response.data) // Message
            console.log(error.response.status) // Error code number
            console.log(error.response.headers)
          }
          if (error.response?.status === 403) {
            console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
            window.location.href = '/signout'
          }
        })
  })
}
export const useMyPasswords = () => {
  return useQuery(
    {
      queryKey: ['useMyPasswords'],
      queryFn: () =>
        axios
          .get(`${envApiUrl}/credentials/`, {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            return response.data
          })
          .catch(function (error) {
            if (error.response) {
              console.log('AAA: ', error.response.data) // Message
              console.log(error.response.status) // Error code number
              console.log(error.response.headers)
            }
            if (error.response?.status === 403) {
              console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
              window.location.href = '/signout'
            }
          })
    }

    // {
    //   // enabled: !!year,
    // }
  )
}
export const useHealthmetrictypes = () => {
  return useQuery(
    {
      queryKey: ['useHealthmetrictypes'],

      queryFn: () =>
        axios
          .get(`${envApiUrl}/healthmetrictypes/`, {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            // queryClient.invalidateQueries(['useYearStats'])
            return response.data
          })
          .catch(function (error) {
            if (error.response) {
              console.log('AAA: ', error.response.data) // Message
              console.log(error.response.status) // Error code number
              console.log(error.response.headers)
            }
            if (error.response?.status === 403) {
              console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
              window.location.href = '/signout'
            }
          })
    }

    // {
    //   // enabled: !!year,
    // }
  )
}
export const useHealthmetrictypesWithMeasurements = () => {
  return useQuery({
    queryKey: ['useHealthmetrictypesWithMeasurements'],

    queryFn: () =>
      axios
        .get(`${envApiUrl}/healthmetrictypes/withMeasurements`, {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => {
          // queryClient.invalidateQueries(['useYearStats'])
          return response.data
        })
        .catch(function (error) {
          if (error.response) {
            console.log('AAA: ', error.response.data) // Message
            console.log(error.response.status) // Error code number
            console.log(error.response.headers)
          }
          if (error.response?.status === 403) {
            console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
            window.location.href = '/signout'
          }
        })
  })
}

export const useMeasurements = (healthMetricId) => {
  return useQuery(
    {
      queryKey: ['useMeasurements'],
      queryFn: () =>
        axios
          .get(`${envApiUrl}/measurements/`, {
            headers: {
              'Content-Type': 'application/json',
              id: healthMetricId
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            // queryClient.invalidateQueries(['useYearStats'])
            console.log('Axios got: ', healthMetricId, ': ', response)
            return response.data
          })
          .catch(function (error) {
            if (error.response) {
              console.log('AAA: ', error.response.data) // Message
              console.log(error.response.status) // Error code number
              console.log(error.response.headers)
            }
            if (error.response?.status === 403) {
              console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
              window.location.href = '/signout'
            }
          })
    }

    // {
    //   // enabled: !!year,
    // }
  )
}

export const useMyday = (id) => {
  return useQuery(
    {
      queryKey: ['useMyday', id],
      queryFn: () =>
        id &&
        !isNaN(id) &&
        axios
          .get(`${envApiUrl}/mydays/find_day`, {
            headers: {
              'Content-Type': 'application/json',
              id
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => {
            // queryClient.invalidateQueries(['useYearStats'])
            return response.data
          })
          .catch(function (error) {
            console.log('BBB: ', error) // Message
            if (error.response) {
              console.log('AAA: ', error.response.data) // Message
              console.log(error.response.status) // Error code number
              // console.log(error.response.headers)
            }
            if (error.response?.status === 403) {
              console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
              window.location.href = '/signout'
            }
            // throw new Error(error.response?.data)
            throw new Error(JSON.stringify(error.response?.data))
          })
    }

    // {
    //   enabled: !!id
    // }
  )
}

export const deleteMyday = (id, refetch) =>
  id &&
  axios
    .delete(envApiUrl + '/mydays/', {
      headers: {
        'Content-Type': 'application/json',
        id
        // username: user?.username
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteMyday: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully deleted item')
    })
    .catch((err) => console.error('deleteMyday error: ', err.response?.data?.message, err.response))

export const deleteMeasurement = (id, refetch) =>
  id &&
  axios
    .delete(envApiUrl + '/measurements/', {
      headers: {
        'Content-Type': 'application/json',
        id
        // username: user?.username
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteMyday: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully deleted Measurement')
    })
    .catch((err) => console.error('deleteMeasurement error: ', err.response?.data?.message, err))

export const deleteCredential = (id, refetch) =>
  id &&
  axios
    .delete(envApiUrl + '/credentials/', {
      headers: {
        'Content-Type': 'application/json',
        id
        // username: user?.username
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteCredential: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully deleted item')
    })
    .catch((err) => {
      console.error('deleteCredential error: ', err.response?.data?.message, err.response)
      message.error(err.response?.data?.message)
    })
export const deleteHealthmetricType = (id, refetch) =>
  id &&
  axios
    .delete(envApiUrl + '/healthmetrictypes/', {
      headers: {
        'Content-Type': 'application/json',
        id
        // username: user?.username
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteHealthmetricType: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully deleted HealthmetricType')
    })
    .catch((err) => {
      console.error('deleteHealthmetricType error: ', err.response?.data?.message, err.response)
      message.error(err.response?.data?.message)
    })

export const handleCreateMyday = async (data, user, onOk) => {
  console.log('handleCreateMyday data', data)
  if (!user.id) return {}

  const response = await axios
    .post(envApiUrl + '/mydays', data, {
      headers: {
        'Content-Type': 'application/json',
        userId: user.id
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('Created Myday: response: ', response)
      return response.data
    })
    .finally(() => {
      onOk?.()
      message.success('Successfully created Myday')
    })
    .catch((err) => {
      console.error('handleCreateMyday error: ', err.response?.data?.message, err)
      message.error(err.response?.data?.message)
    })
  return response
}

export const handleCreateMyPassword = (data, onOk) => {
  console.log('handleCreateMyPassword data', data)
  data &&
    axios
      .post(envApiUrl + '/credentials', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create MyPasswords: response: ', response)
        return response.data
      })
      .finally(() => {
        onOk?.()
        message.success('Successfully created credential')
      })
      .catch((err) => {
        console.error('handleCreateMyPassword error: ', err.response?.data?.message, err)
        message.error(err.response?.data?.message)
      })
}

export const handleCreateHealthmetricType = (data, onOk) => {
  console.log('handleCreateHealthmetricType data', data)
  data &&
    axios
      .post(envApiUrl + '/healthmetrictypes', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create healthmetrictypes: response: ', response)
        return response.data
      })
      .finally(() => {
        onOk?.()
        message.success('Successfully created healthmetrictypes')
      })
      .catch((err) => {
        console.error('healthmetrictypes error: ', err.response?.data?.message, err)
        message.error(err.response?.data?.message)
      })
}

export const handleSignup = (data, onOk) => {
  console.log('handleCreateMyday data', data)
  data &&
    axios
      .post(envApiUrl + '/auth/signup', data, {
        headers: {
          'Content-Type': 'application/json'
          // userId: user.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create Myday: response: ', response)
        return response.data
      })
      .finally(() => onOk?.())
      .catch((err) => console.error('Create Myday error: ', err.response?.data?.message, err.response))
}

export const handleSignIn = async (formdata) => {
  const data = { ...formdata, username: formdata.username.toLowerCase() }
  const response = await axios
    .post(envApiUrl + '/auth/signin', data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .catch((err) => {
      console.error('handleSignIn error: ', err.response?.data?.message, err)
    })
  console.log('Login: response: ', response)
  if (response?.data?.loggedIn) {
    message.success('Signin successful!')
    window.location.reload()
    // setTimeout(() => {
    //   window.location.reload()
    // }, 100)
    return response.data.user
  } else {
    console.log('SignIn: failed: ', response?.data?.message)
    message.error('Sign in failed: ' + response?.data?.message)
  }
}

export const handleUpdateMyday = (data, myday, onOk) => {
  console.log('handleUpdateMyday data', data, myday)
  myday.id &&
    axios
      .put(envApiUrl + '/mydays', data, {
        headers: {
          'Content-Type': 'application/json',
          id: myday.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Update Myday: response: ', response)
        return response.data
      })
      .finally(() => {
        onOk?.()
        message.success('Successfully updated MyDay')
      })
      .catch((err) => {
        console.error('handleUpdateMyday error: ', err.response?.data?.message, err)
        message.error(err)
      })
}

export const handleUpdateCredential = (data, credential, onOk) => {
  console.log('handleUpdateCredential data', data, credential)
  credential.id &&
    axios
      .put(envApiUrl + '/credentials', data, {
        headers: {
          'Content-Type': 'application/json',
          id: credential.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Update credential: response: ', response)
        return response.data
      })
      .finally(() => {
        onOk?.()
        message.success('Successfully updated credential ')
      })
      .catch((err) => {
        console.error('handleUpdateCredential error: ', err.response?.data?.message, err.response)
        message.error(err.response?.data?.message)
      })
}

export const handleCreateMydaysYear = (data = {}, refetch) => {
  console.log('handleCreateMydaysYear')
  axios
    .post(`${envApiUrl}/mydays/createyear`, data, {
      headers: {
        'Content-Type': 'application/json',
        ...data
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('Create year response: ', response)
      return response.data
    })
    .finally(() => refetch?.())
    .catch((err) => console.error('Create year error: ', err.response?.data?.message, err.response))
}

export const handleCreateYear = (data = {}, refetch) => {
  console.log('Creating year')
  axios
    .post(`${envApiUrl}/registrations/createyear`, data, {
      headers: {
        'Content-Type': 'application/json',
        ...data
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('Create year response: ', response)
      return response.data
    })
    .finally(() => refetch?.())
    .catch((err) => console.error('Create year error: ', err.response?.data?.message, err.response))
}

export const useLogs = () => {
  return useQuery(
    {
      queryKey: ['logs'],
      queryFn: () =>
        // user &&
        axios
          .get(envApiUrl + '/logs', {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          })
          .then((response) => response.data)
    }

    // {
    //   enabled: true
    // }
  )
}

export const updateProfile = (data = {}, refetch) => {
  console.log('Updating user profile with: ', data)
  axios
    .patch(`${envApiUrl}/users/`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      return response.data
    })
    .finally(() => {
      refetch?.()
      // const queryClient = useQueryClient()
      message.success('Successfully updated user profile')
      // queryClient.invalidateQueries(['userInfo'])
    })
    .catch((err) => {
      console.error('updateProfile error: ', err.response?.data?.message, err)
      message.error(err)
    })
}

export const updateFile = (data = {}, refetch) => {
  console.log('Updating  file with: ', data)
  axios
    .patch(`${envApiUrl}/files/`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully updated file')
    })
    .catch((err) => {
      console.error('updateFile error: ', err.response?.data?.message, err)
      message.error(err)
    })
}

export const updateHealthmetricType = (data = {}, refetch) => {
  console.log('Updating healthmetrictypes with: ', data)
  axios
    .put(`${envApiUrl}/healthmetrictypes/`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully updated Healthmetric Type.')
    })
    .catch((err) => {
      console.error('updateHealthmetricType error: ', err.response?.data?.message, err)
      message.error(err)
    })
}

export const updateDay = (data = {}, refetch) => {
  console.log('Updating day with: ', data)
  axios
    .put(`${envApiUrl}/registrations/`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      // console.log('updateDay response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully updated registration')
    })
    .catch((err) => {
      console.error('updateDay error: ', err.response?.data?.message, err)
      message.error(err)
    })
}

export const updateHourRateForMonth = (data = {}, refetch) => {
  console.log('Updating hourRate with: ', data)
  axios
    .put(`${envApiUrl}/registrations/updateHourRate`, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('updateHourRateForMonth response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
      message.success('Successfully updated hourRates')
    })
    .catch((err) => {
      console.error('updateHourRateForMonth error: ', err.response?.data?.message, err)
      message.error(err)
    })
}

export const useDebounce = (fn, dep) =>
  useEffect(() => {
    waitForNSeconds(2, () => {
      if (dep?.length > 0) fn?.()
    })
    // return () => clearTimeout(getData)
    // const getData = setTimeout(() => {
    //   if (dep?.length > 0) fn?.()
    // }, 2000)
    // return () => clearTimeout(getData)
  }, [dep])

import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Image, Popconfirm, Tooltip } from 'antd'
import { useState } from 'react'
import { FileDownloadLink } from '../../utils/utilFunctions'
import Meta from 'antd/es/card/Meta'
import { deleteFile, envApiUrl } from '../../fetchHooks/useFetchHooks'
import VideoPlayerModal from './VideoPlayerModal'
import FileUpdateDebounced from './FileUpdateDebounced'

export const getFileSize = (sizeInBytes) => (typeof sizeInBytes === 'number' && sizeInBytes ? (sizeInBytes / 1024 / 1024).toFixed(2) : '')

const FileItem = ({ file, refetch, compact = false, isEdit = false }) => {
  const [showModal, setShowModal] = useState(false)

  const { id, mimetype, name, description } = file
  const regexVideo = /\.(mp4|avi|flv|wmv|mov|mkv|m4v)$/i
  const regexPdf = /\.(pdf)$/i

  const isVideo = regexVideo.test(name)
  const size = getFileSize(file.size)
  let iconImageSrc =
    'https://img.freepik.com/free-vector/illustration-data-folder-icon_53876-6329.jpg?w=1380&t=st=1679521507~exp=1679522107~hmac=6e52b5281e8ed85847fcdabb8a2044f86c04ab137b4d4f200e336d945b75a463'
  if (isVideo) iconImageSrc = 'https://www.freeiconspng.com/thumbs/video-play-icon/video-play-icon-24.png'
  // 'https://img.freepik.com/free-vector/video-design-abstract-concept-illustration_335657-3820.jpg?w=1380&t=st=1679521171~exp=1679521771~hmac=a355b61a45c67f1a75424963123da77954070ff78926ea134be94e10a8e776b9'
  if (regexPdf.test(name))
    iconImageSrc =
      'https://cdn-icons-png.flaticon.com/512/337/337946.png?w=1380&t=st=1679521403~exp=1679522003~hmac=41a081ab325045db6fc501f53c61606413df69f6c6165da15fcd092ffb786714'

  if (isEdit)
    return (
      <div style={{ margin: 4 }} key={id}>
        <Card
          style={{
            // width: 300,
            // height: compact ? 300 : 400,
            // margin: 16,
            overflow: 'hidden'
          }}
          // cover={<img alt="example" src={iconImageSrc} style={{ width: '80%', height: '80%', padding: 16, margin: '0 auto' }} />}
          actions={[
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() => deleteFile(id, refetch)}
              onCancel={() => console.log('Canceled')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
            </Popconfirm>,
            <Button
              // type="danger"
              onClick={() => setShowModal(`${envApiUrl}/files/download/${id}`)}
              // shape="round"
              size="default"
              icon={<PlayCircleOutlined />}
              // ghost
              style={{ border: 0 }}
              disabled={!isVideo}
            />
          ]}
        >
          {/* <Meta
          // avatar={<Avatar src="https://joesch.moe/api/v1/random" />}
          title={<Tooltip title={name}>{`${name}`}</Tooltip>}
          description={`${mimetype}
                  ${size} MB`}
        /> */}
          <FileUpdateDebounced fileId={id} label="Name" propertyName="name" value={name} />
          <FileUpdateDebounced fileId={id} label="Description" propertyName="description" value={description} />
        </Card>
        <Divider />
        {isVideo && showModal && <VideoPlayerModal videoUrl={showModal} onClose={() => setShowModal(false)} />}
      </div>
    )

  return (
    <div style={{ margin: 4 }} key={id}>
      <Card
        style={{
          width: 266,
          height: '100%',
          // margin: 16,
          borderRadius: 16,
          overflow: 'hidden'
        }}
        cover={
          <Image
            // height={400}
            preview={false}
            src={iconImageSrc}
            onClick={isVideo ? () => setShowModal(`${envApiUrl}/files/download/${id}`) : undefined}
            style={{
              height: compact ? 150 : '250px',
              margin: '0 auto',
              objectFit: 'contain',
              cursor: 'pointer',
              padding: regexPdf.test(name) ? 16 : 24
            }}
          />
        }
        // cover={<img alt="example" src={iconImageSrc} style={{ width: '80%', height: '80%', padding: 16, margin: '0 auto' }} />}
        actions={[
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => deleteFile(id, refetch)}
            onCancel={() => console.log('Canceled')}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
          </Popconfirm>,
          <Button
            // type="danger"
            onClick={() => setShowModal(`${envApiUrl}/files/download/${id}`)}
            // shape="round"
            size="default"
            icon={<PlayCircleOutlined />}
            // ghost
            style={{ border: 0 }}
            disabled={!isVideo}
          />,

          <FileDownloadLink fileId={id} fileName={name} />
        ]}
      >
        <Tooltip title={name}>
          <b>{`${description}`}</b>
          <p>{`${mimetype}
                  ${size} MB`}</p>
        </Tooltip>
      </Card>
      {showModal && <VideoPlayerModal videoUrl={showModal} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default FileItem

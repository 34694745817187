import { useState } from 'react'
import { useEscapeKey } from './Files'

const VideoPlayerModal = ({ videoUrl, onClose }) => {
  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)

  // Calculate video dimensions to fit within screen
  const calculateVideoDimensions = () => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const aspectRatio = videoWidth / videoHeight

    let width = screenWidth * 0.8 // 80% of screen width
    let height = width / aspectRatio

    if (height > screenHeight * 0.8) {
      // If calculated height is larger than 80% of screen height, resize height
      height = screenHeight * 0.8
      width = height * aspectRatio
    }

    return { width, height }
  }

  // Handle video metadata load
  const handleVideoLoad = (event) => {
    const { videoWidth, videoHeight } = event.target
    setVideoWidth(videoWidth)
    setVideoHeight(videoHeight)
  }
  useEscapeKey(onClose)

  const videoDimensions = calculateVideoDimensions()

  return (
    <div className="modal-overlay" onClick={onClose} style={{ zIndex: 9999 }}>
      <div className="modal-content">
        <video
          src={videoUrl}
          autoPlay
          controls
          onLoadedMetadata={handleVideoLoad}
          style={{ width: videoDimensions.width || 200, height: videoDimensions.height || 300 }}
        />
      </div>
    </div>
  )
}

export default VideoPlayerModal

import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { envApiUrl } from '../../fetchHooks/useFetchHooks'
import { Button, Upload, Progress, Space, Typography, message } from 'antd'
import { FileOutlined } from '@ant-design/icons'
import { Files } from '../../pages/MyDayPage/Files'
import { Images } from '../../pages/MyDayPage/Images'
import axios from 'axios'
import { getFileSize } from '../../pages/MyDayPage/FileItem'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/en-gb'

// Extend and update the locale
dayjs.extend(updateLocale)
dayjs.updateLocale('en-gb', {
  weekStart: 1 // Set Monday as the first day of the week
})

export const formatTimeDuration = (s) => {
  let seconds = Math.floor(s)
  if (s < 60) return seconds + 'seconds'
  const minutes = Math.floor(s / 60)
  seconds = seconds - minutes * 60
  return minutes + ' minutes and ' + seconds + ' seconds'
}

const UploadFilesDragger = ({ myday, onOk }) => {
  const [fileList, setFileList] = useState({})
  const queryClient = useQueryClient()
  const handleUpload = ({ file }) => {
    const getFileObject = (progress, estimated) => {
      return {
        name: file.name,
        uid: file.uid,
        size: file?.size,
        progress: Math.ceil(progress * 100),
        estimated: estimated || 0
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    myday.id &&
      axios
        .post(envApiUrl + '/files', formData, {
          headers: {
            'content-type': 'multipart/form-data',
            mydayId: myday.id
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken',
          onUploadProgress: (progressEvent) => {
            setFileList((prev) => {
              return {
                ...prev,
                [file.uid]: getFileObject(progressEvent?.progress, progressEvent?.estimated)
              }
            })
          }
        })
        .then((response) => {
          message.success(file.name + ' uploaded successfully.')
          return response.data
        })
        .catch((err) => {
          console.error('Create File error: ', err.response?.data?.message, err.response)
          message.error('upload failed.')
        })
        .finally(() => {
          queryClient.invalidateQueries(['useFilesOnly'])
          queryClient.invalidateQueries(['useImages'])
        })
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <h3>Upload images or other files</h3>
      <Upload.Dragger
        multiple
        showUploadList={false}
        listType="picture"
        customRequest={handleUpload}
        beforeUpload={(file) => {
          console.log('File: ', file)
          if (file.size > 1000_000_000) {
            message.error('File can not exceed 1GB.')
            return false // return true hvis filen skal legges Title, false hvis den feks er for stor
          }
        }}
      >
        Drag files here OR <br /> <Button>Click to Upload</Button>{' '}
      </Upload.Dragger>
      {Object.values(fileList)?.map((file, index) => {
        console.log('file', file)
        return (
          <Space direction="vertical" style={{ padding: 8, backgroundColor: 'rgba(0,0,0,0.05)', width: '100%' }} key={file.uid}>
            <Space>
              <FileOutlined />
              <Typography>
                {file?.name}, {getFileSize(file?.size)}MB
              </Typography>
              <Typography.Text type="secondary">
                {' '}
                is being uploaded, time remaining is {formatTimeDuration(file?.estimated)}
              </Typography.Text>
            </Space>
            <Progress percent={file.progress} />
          </Space>
        )
      })}
      {myday?.file_count > 0 && (
        <>
          <Typography>Existing files:</Typography>
          <Images myday={myday} compact isEdit />
          <Files myday={myday} compact isEdit />
        </>
      )}
    </Space>
  )
}

export default UploadFilesDragger

import axios from 'axios'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { envApiUrl } from '../../fetchHooks/useFetchHooks'
// import { useDispatch } from "react-redux"
// import { storeAppvariables } from '../redux/actions'

const Signout = () => {
  const { isPending, isError, isSuccess } = useQuery({
    queryKey: ['userData'],
    queryFn: () => {
      return axios
        .post(
          envApiUrl + '/auth/signout',
          {},
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken_testtest',
            xsrfHeaderName: 'X-CSRFToken'
          }
        )
        .then((response) => {
          console.log('response: ', response)
          return response.data
        })
    }
  })

  if (isPending) {
    return <h1>Loading...</h1>
  }

  if (isError) {
    return <h1>Something went wrong</h1>
  }

  if (isSuccess) {
    window.location.href = '/'
  }

  return <ul>{isSuccess && <p>Signed out</p>}</ul>
}

export default Signout

import { useQueryClient } from '@tanstack/react-query'
import { Input } from 'antd'
import { useEffect, useState } from 'react'
import { updateDay } from '../../fetchHooks/useFetchHooks'
import TextArea from 'antd/es/input/TextArea'

const RegistrationInputDebounced = ({ label, propertyName, registrationId, value, style, isNumber = false, textArea = false }) => {
  const [inputValue, setInputValue] = useState(value)
  const [debounceTimer, setDebounceTimer] = useState(null)
  const queryClient = useQueryClient()

  const isValueUpdated = inputValue === value

  const handleInputChange = (event) => {
    const value = event.target.value
    isNumber ? !isNaN(value) && setInputValue(value) : setInputValue(value)
    setInputValue(value)

    // Clear previous timer if any
    clearTimeout(debounceTimer)

    // Set a new timer to send the PUT request after 2 seconds
    // const newDebounceTimer = setTimeout(() => {
    //   updateProfile({ [propertyName]: value, id: userId }, () => {
    //     queryClient.invalidateQueries(['userInfo'])
    //   })
    const newDebounceTimer = setTimeout(() => {
      updateDay({ [propertyName]: value, id: registrationId }, () => {
        queryClient.invalidateQueries(['useRegistrations'])
        queryClient.invalidateQueries(['useYearStats'])
      })
    }, 2000)

    setDebounceTimer(newDebounceTimer)
  }

  useEffect(() => {
    // Clear the timer when the component unmounts or when inputValue changes
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [debounceTimer, inputValue])

  if (textArea)
    return (
      <TextArea
        placeholder={label}
        value={inputValue}
        // addonBefore={label + ':'}
        status={!isValueUpdated && 'warning'}
        autoSize={{ minRows: 1, maxRows: 6 }}
        onChange={handleInputChange}
        style={style}
      />
    )
  return (
    <Input
      placeholder={label}
      value={inputValue}
      // addonBefore={label + ':'}
      status={!isValueUpdated && 'warning'}
      onChange={handleInputChange}
      style={style}
    />
  )
}

export default RegistrationInputDebounced

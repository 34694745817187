import React, { useEffect } from 'react'
import { Layout, Menu, theme } from 'antd'
import { Link } from 'react-router-dom'
import './Navbar.scss'
export const isMobile = window.innerWidth < 500

const NavMonths = ({ year, month, hidden = false, setMonth, setYear }) => {
  if (hidden) return

  try {
    const yearNumber = parseInt(year)
  } catch (error) {
    console.log('error: ', error)
    return <></>
  }
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const handleChangeMonth = (op) => {
    let newShowMonth = month
    let newYear = parseInt(year)

    if (op === 'sub') {
      newShowMonth -= 1
    }
    if (op === 'add') {
      newShowMonth += 1
    }
    if (newShowMonth > 12) {
      newShowMonth = 1
      newYear += 1
    }
    if (newShowMonth < 1) {
      newShowMonth = 12
      newYear -= 1
    }
    setMonth(newShowMonth)
    setYear(newYear)
  }

  const menuItems = () => [
    {
      key: 0,
      label: <Link onClick={() => handleChangeMonth('sub')}>{'<'}</Link>
    },
    {
      key: 1,
      label: <Link onClick={() => setMonth(1)}>Jan</Link>
    },
    {
      key: 2,
      label: <Link onClick={() => setMonth(2)}>Feb</Link>
    },
    {
      key: 3,
      label: <Link onClick={() => setMonth(3)}>Mar</Link>
    },
    {
      key: 4,
      label: <Link onClick={() => setMonth(4)}>Apr</Link>
    },
    {
      key: 5,
      label: <Link onClick={() => setMonth(5)}>May</Link>
    },
    {
      key: 6,
      label: <Link onClick={() => setMonth(6)}>Jun</Link>
    },
    {
      key: 7,
      label: <Link onClick={() => setMonth(7)}>Jul</Link>
    },
    {
      key: 8,
      label: <Link onClick={() => setMonth(8)}>Aug</Link>
    },
    {
      key: 9,
      label: <Link onClick={() => setMonth(9)}>Sep</Link>
    },
    {
      key: 10,
      label: <Link onClick={() => setMonth(10)}>Oct</Link>
    },
    {
      key: 11,
      label: <Link onClick={() => setMonth(11)}>Nov</Link>
    },
    {
      key: 12,
      label: <Link onClick={() => setMonth(12)}>Dec</Link>
    },
    {
      key: 13,
      label: (
        <Link onClick={() => handleChangeMonth('add')}>
          <span>{'>'}</span>
        </Link>
      )
    }
  ]
  return (
    <Layout style={{ background: 'transparent' }}>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={[`${month}`]}
        items={menuItems()}
        style={{ textAlign: 'center' }}
        className="navbar navbar-sub"
      />
    </Layout>
  )
}

export default NavMonths

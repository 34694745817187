import React, { useEffect } from 'react'
import { AppContainer } from '../../styles'
import Login from '../LoginPage/Login'
import CookieConsent from 'react-cookie-consent'
import Dashboard from '../../components/Dashboard/Dashboard'
import Navbar from '../../components/Navbar/Navbar'
import { envApiUrl, useIsLoggedIn, useUserInfo } from '../../fetchHooks/useFetchHooks'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const RenderUserInfo = () => {
  const { data: user, isSuccess, isLoading } = useUserInfo()
  switch (user?.role) {
    case 'USER':
      return (
        <AppContainer>
          <Dashboard user={user} />
        </AppContainer>
      )
    case 'MASTER':
      return (
        <AppContainer>
          <Dashboard user={user} />
        </AppContainer>
      )

    default:
      return <p>Some error occured...</p>
  }
}

const Home = () => {
  const { data: isLoggedIn, isPending } = useIsLoggedIn()

  if (isPending) return <Login />

  return (
    <div>
      {isLoggedIn && <Navbar />}
      {!isLoggedIn ? <Login /> : <RenderUserInfo />}
      <CookieConsent
        location="bottom"
        // buttonText="Sure man!!"
        cookieName="MydayCookieConsent"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

export default Home

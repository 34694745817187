import { CopyOutlined, DownloadOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { format } from 'date-fns'
import Button from 'antd/lib/button'
import { envApiUrl } from '../fetchHooks/useFetchHooks'

export const CopyToClipboardButton = ({ text }) => (
  <Button
    icon={<CopyOutlined />}
    shape="round"
    size="small"
    style={{ border: 0, right: 8, position: 'absolute' }}
    onClick={async () => {
      await navigator.clipboard.writeText(text)
      message.success('Copied to clipboard')
    }}
  />
)

export function waitForNSeconds(n, func) {
  const start = performance.now()

  function wait(timestamp) {
    if (timestamp - start < n * 1000) {
      // If less than 3 seconds have passed, wait for the next frame
      requestAnimationFrame(wait)
    } else {
      // If 3 seconds have passed, run the code
      func?.()
    }
  }

  // Start the animation loop
  requestAnimationFrame(wait)
}

export function addHttps(url) {
  // Regular expression to check if the string is a valid URL
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/

  // Check if the string matches the URL regex
  if (urlRegex.test(url)) {
    // If the string starts with 'http://' instead of 'https://', replace it with 'https://'
    if (url.startsWith('http://')) {
      return url.replace('http://', 'https://')
    } else {
      // Otherwise, add 'https://' to the beginning of the string
      return 'https://' + url
    }
  } else {
    // If the string is not a valid URL, return it unchanged
    return url
  }
}

export const formatDateFull = (date) => format(date, "eee d. MMM yyyy 'kl.' HH:mm")
export const formatTimeOnly = (date) => format(date, "'kl.' HH:mm")
export const formatDateOnly = (date) => format(date, "eee d. MMM ''yy")

export const SensitiveTextField = ({ text, show, onClick }) => {
  if (!text) return <></>
  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          onClick?.()
        }}
      >
        {show ? text : text.replace(/./g, '*')}
      </span>
    </>
  )
}

export const SensitiveRichTextField = ({ text, show, onClick, className }) => {
  if (!text) return <></>
  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          onClick?.()
        }}
      >
        {/* <div dangerouslySetInnerHTML={{ __html: show ? text : text.replace(/./g, '*') }} /> */}
        {/* {show ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text.replace(/./g, '*')} */}
        {show ? (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <div className={className} style={{ overflowWrap: 'break-word' }}>
            {text.replace(/./g, '*')}
          </div>
        )}
      </span>
    </>
  )
}
export const RichTextField = ({ text, onClick }) => {
  if (!text) return <></>
  return (
    <>
      <span style={{ cursor: 'pointer' }} onClick={() => onClick?.()}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </span>
    </>
  )
}

export function FileDownloadLink({ fileId, fileName }) {
  const downloadUrl = `${envApiUrl}/files/download/${fileId}`

  return (
    <a href={downloadUrl} download={fileName}>
      <DownloadOutlined />
    </a>
  )
}

import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import { deleteHealthmetricType, useHealthmetrictypes, useUserInfo } from '../../fetchHooks/useFetchHooks'
import { AppContainer, LinkButton } from '../../styles'
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { CreateHealthMetricModal } from './CreateHealthMetricModal'
import HealthMetricInputDebounced from './HealthMetricInputDebounced'

export const HealthmetrictypesTable = ({ setShowEditItem, healthMetricTypes, refetch }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  console.log('AdminPage healthMetricTypes: ', healthMetricTypes)
  const columns = !healthMetricTypes
    ? []
    : [
        {
          title: 'Name',
          dataIndex: 'name',
          sorter: (a, b) => a.name.localeCompare(b.name),
          key: 'name',
          render: (name, item) => <LinkButton onClick={() => setShowEditItem?.(item)}>{name}</LinkButton>,
          responsive: ['xs', 'sm', 'lg']
        },
        {
          title: 'Description',
          dataIndex: 'description',
          sorter: (a, b) => a.description.localeCompare(b.description),
          key: 'description',
          render: (description, item) => <LinkButton onClick={() => setShowEditItem?.(item)}>{description}</LinkButton>,
          responsive: ['xs', 'sm', 'lg']
        },

        {
          title: 'Action',
          key: 'action',
          render: (text, item) => (
            <Space.Compact size="middle">
              {/* <Tooltip title="Edit">
                <Button
                  shape="round"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => setShowEditMydayModal?.(item)}
                  className="edit-button"
                />
              </Tooltip> */}
              <Tooltip title="Delete item">
                <Popconfirm
                  title={`Are you sure to delete ${item.description}?`}
                  onConfirm={() => deleteHealthmetricType(item.id, refetch)}
                  onCancel={() => console.log('Canceled')}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="edit-button" shape="round" size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Space.Compact>
          ),
          responsive: ['xs', 'sm', 'lg']
        }
      ]

  return (
    <>
      <Table dataSource={healthMetricTypes} columns={columns} rowKey="id" style={{ marginBottom: 16 }} />
      <Button onClick={() => setShowCreateModal(true)}>Create new</Button>
      <CreateHealthMetricModal
        show={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onOk={() => {
          setShowCreateModal(false)
          refetch?.()
        }}
      />
    </>
  )
  // return <Table dataSource={healthMetricTypes} columns={columns} rowKey="id" style={{ marginBottom: 16 }} />
}

const AdminPage = () => {
  const { data: user, isLoading } = useUserInfo()
  const [searchInput, setSearchInput] = useState('')
  const [showEditItem, setShowEditItem] = useState()
  const { data: healthMetricTypes, refetch } = useHealthmetrictypes()
  const getUpdatedItem = (id) => healthMetricTypes?.find((i) => i.id === showEditItem?.id)

  // if (!user && isLoading) return 'Loading...'
  return (
    <div>
      <Navbar />
      <AppContainer>
        <h1>Healthmetric types:</h1>
        Search: <input value={searchInput} type="text" onChange={(e) => setSearchInput(e.target.value)} />
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '2rem' }}>
            <HealthmetrictypesTable
              healthMetricTypes={healthMetricTypes.filter?.(({ description }) =>
                description?.toLowerCase().includes(searchInput.toLowerCase())
              )}
              refetch={refetch}
              setShowEditItem={setShowEditItem}
            />
          </div>

          <div style={{ margin: '2rem' }}>
            Now editing: {getUpdatedItem()?.name}
            {!!showEditItem?.id && (
              <div style={{ width: '100%' }}>
                <HealthMetricInputDebounced
                  healthmetricId={getUpdatedItem()?.id}
                  label="Name"
                  propertyName="name"
                  value={getUpdatedItem()?.name}
                  key={'name'}
                />
                <HealthMetricInputDebounced
                  healthmetricId={getUpdatedItem()?.id}
                  label="Description"
                  propertyName="description"
                  value={getUpdatedItem()?.description}
                  key={'description'}
                />
                <HealthMetricInputDebounced
                  healthmetricId={getUpdatedItem()?.id}
                  label="Unit"
                  propertyName="unit"
                  value={getUpdatedItem()?.unit}
                  key={'Unit'}
                />
                <HealthMetricInputDebounced
                  healthmetricId={getUpdatedItem()?.id}
                  label="Reference Low"
                  propertyName="ref_lower"
                  value={getUpdatedItem()?.ref_lower}
                  key={'ref_lower'}
                  isNumber
                />
                <HealthMetricInputDebounced
                  healthmetricId={getUpdatedItem()?.id}
                  label="Reference High"
                  propertyName="ref_upper"
                  value={getUpdatedItem()?.ref_upper}
                  key={'ref_upper'}
                  isNumber
                />
                <HealthMetricInputDebounced
                  healthmetricId={getUpdatedItem()?.id}
                  label="Comment"
                  propertyName="comment"
                  value={getUpdatedItem()?.comment}
                  key={'comment'}
                  textArea
                />
              </div>
            )}
          </div>
        </div>
      </AppContainer>
    </div>
  )
}

export default AdminPage

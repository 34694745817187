import React from 'react'
// import 'antd/dist/reset.css'
import './App.scss'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/HomePage/Home'
import Year from './components/Year/Year'
import Signout from './components/Signout/Signout'
import ShowLogs from './components/ShowLogs/ShowLogs'
import Month from './components/Month/Month'
import MyDay from './pages/MyDayPage/MyDayPage'
import MydayDetails from './pages/MyDayPage/MydayDetails'
import MyPasswords from './pages/MyPasswords/MyPasswords'
import { Footer } from './components/Footer/Footer'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
const queryClient = new QueryClient()
//
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/registrations" element={<Month />} />
          <Route path="/year" element={<Year />} />
          <Route path="/myday" element={<MyDay />} />
          <Route path="/mypasswords" element={<MyPasswords />} />
          <Route path="/mydaydetails/:id" element={<MydayDetails />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/logs" element={<ShowLogs />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/admin" element={<AdminPage />} />
          {/* <Route path="/signin" element={<Signin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/logs" element={<ShowLogs />} /> */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </QueryClientProvider>
  )
}

// function App() {
//   return (
//     <div className="App">
//       <header>
//         <p>Salary calculator</p>
//       </header>
//       <Month />
//     </div>
//   );
// }

// export default App;
